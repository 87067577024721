const FAQ = () =>{
    return(
        <div className="container">
        <p><strong>Welcome to the E&I Intelligent Logistics Capacity Planner</strong></p>
        <p>We’re here to help. Choose a method that’s convenient for you and we’ll help you find the best solution. But first, check our Frequent Asked Questions (FAQ) to get quick answers to the most popular topics. If you can’t find an answer to your question there, then download our guidelines or contact a system administrator below for further queries.</p>
        
        <p><strong>Administrators</strong></p>
        <p>Please contact our system administrators if you require any assistance while using the E&I ILP tool, such as:</p>
        
        <p>-	System Issues</p>
        <p>-	Downloading a report, column fields, etc. from the tool</p>
        <p>-	Providing feedbacks on your user experience</p>
        <p>-	Others</p>

        <p>Our system administrators’ local times are listed of your convenience.</p>

        <p><strong>FAQ</strong></p>
        <p>1.	Issue</p>
        <p>2.	Issue</p>
        <p>3.	Issue</p>
        </div>
        
      
    )
}

export default FAQ