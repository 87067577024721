import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Oceanpic from "../Images/Ocean_Page_Logo.png";
import AirPic from "../Images/Air.png"
import RoadPic from "../Images/Road.png"

import { useNavigate } from "react-router-dom";
import "../Styles/Home.css"
import { SignInButton } from "../Components/SignInButton";
import HomeCard from "../Components/HomeCard";

const Home = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <h4 className="text-center">Welcome!</h4>
        <p className="text-center">Please select one (1) to continue</p>          
        <div className="flex-row justify-center align-center h-70v">
            <HomeCard text={"Ocean"} url={"/Ocean"} image={Oceanpic} />
            <HomeCard text={"Air"} url={"/Air"} image={AirPic} />
            <HomeCard text={"Road"} url={"/na_road"} image={RoadPic} />
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="flex-col align-center">
        <p className="text-center">Please sign in!</p>
          <SignInButton type={"default"}/>
        </div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default Home;
