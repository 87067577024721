import { DatePicker } from "antd"
import React, { useContext, useState } from "react"
import { airContext } from ".."

function ForecastTableHeader() {
    const {
        setCurrentForecastMonth,
        forecastMonthStore,
        setForecastMonthStore,
    } = useContext(airContext)
    return (
        <div className="forecast-col-header flex-row">
            <h6>Current Forecast</h6>
            <DatePicker
                className="road-forecast-month-picker"
                picker="month"
                style={{
                    height: 35,
                }}
                value={forecastMonthStore}
                placeholder="Forecast Month"
                onChange={(date, dateString) => {
                    setCurrentForecastMonth(dateString)
                    setForecastMonthStore(date)
                }}
            />
        </div>
    )
}

export default ForecastTableHeader
