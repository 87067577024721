import React, { useContext } from "react"
import { CaretDownOutlined } from "@ant-design/icons"
import { Checkbox } from "antd"
import { updateRoadColumnCookies } from "../Utils/updateCookies"
import { setScrollListner } from "../Utils/scrollListner"
import { useDispatch, useSelector } from "react-redux"
import { updateRoadColumns } from "../../Redux/actions/roadActions"
import { roadContext } from ".."

function SideBar({
    year = 2024,
    quarter = "Q2",
    columns,
    page,
    forecastColumn = null,
}) {
    const {region} = useContext(roadContext)
    const dispatch = useDispatch()
    const getUpdatedColumns = (currentColumn, localCols) => {
        let index = localCols.findIndex(
            (col) => col.dataIndex === currentColumn.dataIndex
        )
        let column = localCols[index]
        column = { ...column, visibility: !column.visibility }
        localCols[index] = column
        return localCols
    }

    const updateStore = (updatedColumns) => {
        updateRoadColumnCookies(updatedColumns,region)
        dispatch(updateRoadColumns(updatedColumns))
    }
    const areAllSelected = () => {
        let flag = true
        if (columns)
            columns.forEach((col) => {
                flag &= col.visibility
            })
        return flag
    }
    return (
        <div className="sidebar flex-col">
            <div className="flex-col" style={{ lineHeight: 1.35 }}>
                <span className="gray-text side-text">Year</span>
                <span className="blue-text main-text">{year}</span>
                <span className="gray-text side-text">Quarter</span>
                <span className="blue-text main-text">{quarter}</span>
            </div>
            <div className="flex-col">
                <span className="gray-text side-text align-center ">
                    Choose Column Data
                </span>
                <div className="flex-col sidebar-checkbox-container flex-col align-left">
                    <Checkbox
                        checked={areAllSelected()}
                        onChange={(e) => {
                            columns = columns.map((item, index, array) => {
                                // if (index === array.length - 1) return item
                                // else
                                return {
                                    ...item,
                                    visibility: e.target.checked,
                                }
                            })
                            updateStore(columns)
                        }}
                    >
                        All
                    </Checkbox>
                    {columns &&
                        columns.map((col) => (
                            <Checkbox
                                key={col.key}
                                checked={col.visibility}
                                name={col.dataIndex}
                                onChange={(e) => {
                                    let updatedColumns = getUpdatedColumns(
                                        col,
                                        columns
                                    )
                                    updateStore(updatedColumns)
                                    setScrollListner()
                                }}
                            >
                                {col.title}
                            </Checkbox>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default SideBar
