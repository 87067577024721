import axios from "axios"
import {
    UPDATE_DUPLICATE_AIR_DATA,
    UPDATE_FILTERED_AIR_DATA,
    UPDATE_AIR_COLUMNS,
    UPDATE_AIR_DATA,
} from "../types/airActionTypes"

//Air actions
export const updateAirData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_AIR_DATA,
            payload: data,
        })
    } catch (err) {}
}
export const updateDuplicateAirData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_DUPLICATE_AIR_DATA,
            payload: data,
        })
    } catch (err) {}
}

export const updateAirColumns = (columns) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_AIR_COLUMNS,
            payload: columns,
        })
    } catch (err) {}
}
export const updateFilteredAirData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_FILTERED_AIR_DATA,
            payload: data,
        })
    } catch (err) {}
}

export const getAirForecastData = (config) => async (dispatch) => {
    try {
        axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/air/forecast`,
                config
            )
            .then(({ data }) => {
                dispatch(updateAirData(null))
                return data
            })
            .then((data) => {
                dispatch(updateAirData(data))
            })
            .catch((err) => console.log(err))
    } catch (err) {}
}
