import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import TextButton from "./Button";

export function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch((e) => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = (props) => {
  const {instance} = useMsal()
  return (
    <>
      <TextButton text="Sign in" handler={handleLogin} type={props.type || "default"} styles={props.style || { marginRight: "20px" }}/>
      {/* <button onClick={() => handleLogin(instance)} style={{ marginRight: "20px" }}>Sign in</button> */}
    </>
  );
};

export default SignInButton