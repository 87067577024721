/*
 * In road view, It looks like a single table. but, its not. the table is divided into two tables.
 * here, in this function, we are setting a synchronized scroll between those two tables.
 */
export const setScrollListner = () => {
    const firstTable = document.getElementsByClassName(
        "first-table-container"
    )[0]
    const secondTable = document.getElementsByClassName(
        "second-table-container"
    )[0]
    if (firstTable && secondTable) {
        firstTable.addEventListener("scroll", () => {
            secondTable.scrollTop = firstTable.scrollTop
        })
        secondTable.addEventListener("scroll", () => {
            firstTable.scrollTop = secondTable.scrollTop
        })
    }
}
