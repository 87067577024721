import { useState } from "react"
import { Menu } from "antd"
import { useNavigate } from "react-router-dom"
import { useIsAuthenticated } from "@azure/msal-react"

export const StickyNavigation = () => {
    const [current, setCurrent] = useState("mail")
    const navigate = useNavigate()
    const isAuthenticated = useIsAuthenticated()

    const items = isAuthenticated
        ? [
              {
                  label: "Home",
                  key: "",
              },
              {
                  label: "Ocean",
                  key: "ocean",
              },
              {
                  label: "Air",
                  key: "air",
              },
              {
                  label: "Road",
                  key: "roadRegions",
                  children: [
                      {
                          label: "North America",
                          key: "na_road",
                      },
                      {
                          label: "Europe",
                          key: "europe_road",
                      },
                      {
                          label: "Korea",
                          key: "korea_road",
                      },
                      {
                          label: "Japan",
                          key: "japan_road",
                      },
                       {
                           label: "China",
                          key: "china_road",
                       },
                      {
                          label: "Taiwan",
                          key: "taiwan_road",
                      },
                  ],
              },
          ]
        : [
              {
                  label: "Home",
                  key: "",
              },
          ]
    const onClick = (e) => {
        setCurrent(e.key)
        navigate(`/${e.key}`)
    }

    return (
        <Menu
            style={{
                backgroundColor: "#EBEBEB",
                margin: "-25px 0px 10px 0",
                paddingLeft: "20px",
            }}
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
        />
    )
}
