import Cookies from "universal-cookie"

//setting columns to the localStorage as cookies, to store column view
export const updateRoadColumnCookies = (columns, region = "na_road") => {
    localStorage.setItem(`EI_${region}_Columns`, JSON.stringify(columns))
}
// returning column cookies from the localStorage
export const getRoadColumnCookies = (region = "na_road") => {
    return JSON.parse(localStorage.getItem(`EI_${region}_Columns`))
}

const cookies = new Cookies()
export const updateRoadFirstMonth = (data, region = "na_road") => {
    cookies.set(`${region}FirstMonth`, data.first_month)
    cookies.set(`${region}FirstYear`, data.first_year)
}
export const getRoadFirstMonth = (region = "na_road") => {
    return {
        first_month: parseInt(cookies.get(`${region}FirstMonth`)),
        first_year: parseInt(cookies.get(`${region}FirstYear`)),
    }
}
export const updateRoadColumnFilterCookies = (
    filters,
    region = "na_road"
) => {
    localStorage.setItem(`${region}ColumnFilters`, JSON.stringify(filters))
}

export const getRoadColumnFilterCookies = (region = "na_road") => {
    return JSON.parse(localStorage.getItem(`${region}ColumnFilters`))
}
export const removeRoadColumnFilterCookies = (region = "na_road") => {
    localStorage.removeItem(`${region}ColumnFilters`)
}
