import {
    UPDATE_DUPLICATE_ROAD_DATA,
    UPDATE_FILTERED_ROAD_DATA,
    UPDATE_ROAD_COLUMNS,
    UPDATE_ROAD_DATA,
} from "../types/roadActionTypes"

const road = {
    data: null,
    duplicateData: null,
    filteredData: null,
    columns: null,
}

const roadReducer = (state = road, action) => {
    switch (action.type) {
        case UPDATE_ROAD_DATA:
            return { ...state, data: action.payload }
        case UPDATE_ROAD_COLUMNS:
            return { ...state, columns: action.payload }
        case UPDATE_FILTERED_ROAD_DATA:
            return { ...state, filteredData: action.payload }
        case UPDATE_DUPLICATE_ROAD_DATA:
            return { ...state, duplicateData: action.payload }
        default:
            return state
    }
}
export default roadReducer
