

export const japanRoadColumns = [
    {
      title: "Plant Code",
      dataIndex: "plant_code",
      key: "plant_code",
      filterKey: "plant_code",
      visibility: true,
    },
    {
      title: "Plant City",
      dataIndex: "plant_city",
      key: "plant_city",
      filterKey: "plant_city",
      visibility: true,
    },
    {
      title: "Destination City",
      dataIndex: "destination_city",
      key: "destination_city",
      filterKey: "destination_city",
      visibility: true,
    },
    {
      title: "Equipment",
      dataIndex: "equipment",
      key: "equipment",
      filterKey: "equipment",
      visibility: true,
    },
    {
      title: "Special Service",
      dataIndex: "special_service",
      key: "special_service",
      filterKey: "special_service",
      visibility: true,
    },
    {
      title: "DG",
      dataIndex: "contains_dg",
      key: "contains_dg",
      filterKey: "contains_dg",
      visibility: true,
    },
    {
      title: "Transport Type",
      dataIndex: "transport_type",
      key: "transport_type",
      filterKey: "transport_type",
      visibility: true,
    },
    {
      title: "Month1 History",
      dataIndex: "month1_history",
      key: "month1_history",
      filterKey: "month1_history",
      visibility: true,
    },
    {
      title: "Month2 History",
      dataIndex: "month2_history",
      key: "month2_history",
      filterKey: "month2_history",
      visibility: true,
    },
    {
      title: "Month3 History",
      dataIndex: "month3_history",
      key: "month3_history",
      filterKey: "month3_history",
      visibility: true,
    },
    {
      title: "Month4 History",
      dataIndex: "month4_history",
      key: "month4_history",
      filterKey: "month4_history",
      visibility: true,
    },
    {
      title: "Month5 History",
      dataIndex: "month5_history",
      key: "month5_history",
      filterKey: "month5_history",
      visibility: true,
    },
    {
      title: "Month6 History",
      dataIndex: "month6_history",
      key: "month6_history",
      filterKey: "month6_history",
      visibility: true,
    },
    {
      title: "6 Months History Avg",
      dataIndex: "six_months_history_avg",
      key: "six_months_history_avg",
      filterKey: "six_months_history_avg",
      visibility: true,
    },
    {
      title: "6 Months History Total",
      dataIndex: "six_months_history_total",
      key: "six_months_history_total",
      filterKey: "six_months_history_total",
      visibility: true,
    },


    {
      title: "Month1 Forecast",
      dataIndex: "month1_forecast",
      key: "month1_forecast",
      filterKey: "month1_forecast",
      visibility: true,
    },
    {
      title: "Month2 Forecast",
      dataIndex: "month2_forecast",
      key: "month2_forecast",
      filterKey: "month2_forecast",
      visibility: true,
    },
    {
      title: "Month3 Forecast",
      dataIndex: "month3_forecast",
      key: "month3_forecast",
      filterKey: "month3_forecast",
      visibility: true,
    },
    {
      title: "Month4 Forecast",
      dataIndex: "month4_forecast",
      key: "month4_forecast",
      filterKey: "month4_forecast",
      visibility: true,
    },
    {
      title: "Month5 Forecast",
      dataIndex: "month5_forecast",
      key: "month5_forecast",
      filterKey: "month5_forecast",
      visibility: true,
    },
    {
      title: "Month6 Forecast",
      dataIndex: "month6_forecast",
      key: "month6_forecast",
      filterKey: "month6_forecast",
      visibility: true,
    },
    {
      title: "Month7 Forecast",
      dataIndex: "month7_forecast",
      key: "month7_forecast",
      filterKey: "month7_forecast",
      visibility: true,
    },
    {
      title: "Month8 Forecast",
      dataIndex: "month8_forecast",
      key: "month8_forecast",
      filterKey: "month8_forecast",
      visibility: true,
    },
    {
      title: "Month9 Forecast",
      dataIndex: "month9_forecast",
      key: "month9_forecast",
      filterKey: "month9_forecast",
      visibility: true,
    },
    {
      title: "Month10 Forecast",
      dataIndex: "month10_forecast",
      key: "month10_forecast",
      filterKey: "month10_forecast",
      visibility: true,
    },
    {
      title: "Month11 Forecast",
      dataIndex: "month11_forecast",
      key: "month11_forecast",
      filterKey: "month11_forecast",
      visibility: true,
    },
    {
      title: "Month 2 Forecast",
      dataIndex: "month12_forecast",
      key: "month12_forecast",
      filterKey: "month12_forecast",
      visibility: true,
    },
    {
        title: "Year Forecast",
        dataIndex: "year_forecast",
        key: "year_forecast",
        filterKey: "year_forecast",
        visibility: true,
      },
      {
        title: "Last Updated Datetime",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
        filterKey: "last_updated_datetime",
        visibility: true,
      },
      {
        title: "User Updated",
        dataIndex: "user_updated",
        key: "user_updated",
        filterKey: "user_updated",
        visibility: true,
      },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      filterKey: "comments",
      visibility: true,
    },
  ];
  
  