import { Divider } from "antd"
import React from "react"

function NotFound() {
    return (
        <div className="not-found flex-row align-center">
            <span className="error">404</span>
            <Divider type="vertical" style={{ height:50,fontWeight:"1.5rem"}}/>
            <span className="notfound">Page not found</span>
        </div>
    )
}

export default NotFound
