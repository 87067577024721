export const oceanColumns = [
    // {
    //     title: "ocean_lane_id",
    //     dataIndex: "ocean_lane_id",
    //     key: "ocean_lane_id",
    //     filterKey: "ocean_lane_id",
    //     visibility: true,
    // },
    {
        title: "Lane ID",
        dataIndex: "lane_id",
        key: "lane_id",
        filterKey: "lane_id",
        visibility: true,
    },
    {
        title: "Origin Region",
        dataIndex: "origin_region",
        key: "origin_region",
        filterKey: "origin_region",
        visibility: true,
    },
    {
        title: "Origin Country",
        dataIndex: "origin_location_country",
        key: "origin_location_country",
        filterKey: "origin_location_country",
        visibility: true,
    },
    {
        title: "Origin Location",
        dataIndex: "origin_location",
        key: "origin_location",
        filterKey: "origin_location",
        visibility: true,
    },
    {
        title: "Ship Point",
        dataIndex: "ship_point",
        key: "ship_point",
        filterKey: "ship_point",
        visibility: true,
    },
    {
        title: "Equipment",
        dataIndex: "equipment",
        key: "equipment",
        filterKey: "equipment",
        visibility: true,
    },
    // {
    //     title: "Total Forecast",
    //     dataIndex: "total_forecast",
    //     key: "total_forecast",
    //     filterKey: "total_forecast",
    //     visibility: true,
    // },
    {
        title: "Annual Forecast",
        dataIndex: "suggested_forecast",
        key: "suggested_forecast",
        filterKey: "suggested_forecast",
        visibility: true,
    },
    {
        title: "Prev Year Forecasted Container Volume",
        dataIndex: "forecasted_container_volume",
        key: "forecasted_container_volume",
        filterKey: "forecasted_container_volume",
        visibility: true,
    },
    {
        title: "Prev Year BDP History",
        dataIndex: "bdp_history",
        key: "bdp_history",
        filterKey: "bdp_history",
        visibility: true,
    },
    {
        title: "Prev Year Sankyu History",
        dataIndex: "sankyu_history",
        key: "sankyu_history",
        filterKey: "sankyu_history",
        visibility: true,
    },
    {
        title: "Destination Location",
        dataIndex: "destination_location",
        key: "destination_location",
        filterKey: "destination_location",
        visibility: true,
    },
    {
        title: "Destination Country",
        dataIndex: "destination_location_country",
        key: "destination_location_country",
        filterKey: "destination_location_country",
        visibility: true,
    },
    {
        title: "Destination Region",
        dataIndex: "destination_region",
        key: "destination_region",
        filterKey: "destination_region",
        visibility: true,
    },
    {
        title: "Business Segment",
        dataIndex: "business_segment",
        key: "business_segment",
        filterKey: "business_segment",
        visibility: true,
    },
    // {
    //     title: "Demand Differential",
    //     dataIndex: "demand_differential",
    //     key: "demand_differential",
    //     filterKey: "demand_differential",
    //     visibility: true,
    // },
    // {
    //     title: "Reported Container Activity",
    //     dataIndex: "reported_container_activity",
    //     key: "reported_container_activity",
    //     filterKey: "reported_container_activity",
    //     visibility: true,
    // },
    // {
    //     title: "Month1 History",
    //     dataIndex: "month1_history",
    //     key: "month1_history",
    //     filterKey: "month1_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month2 History",
    //     dataIndex: "month2_history",
    //     key: "month2_history",
    //     filterKey: "month2_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month3 History",
    //     dataIndex: "month3_history",
    //     key: "month3_history",
    //     filterKey: "month3_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month4 History",
    //     dataIndex: "month4_history",
    //     key: "month4_history",
    //     filterKey: "month4_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month5 History",
    //     dataIndex: "month5_history",
    //     key: "month5_history",
    //     filterKey: "month5_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month6 History",
    //     dataIndex: "month6_history",
    //     key: "month6_history",
    //     filterKey: "month6_history",
    //     visibility: true,
    // },
    // {
    //     title: "Six Months History Total",
    //     dataIndex: "six_months_history",
    //     key: "six_months_history",
    //     filterKey: "six_months_history",
    //     visibility: true,
    // },
    {
        title: "Month1 Forecast",
        dataIndex: "month1_forecast",
        key: "month1_forecast",
        filterKey: "month1_forecast",
        visibility: true,
    },
    {
        title: "Month2 Forecast",
        dataIndex: "month2_forecast",
        key: "month2_forecast",
        filterKey: "month2_forecast",
        visibility: true,
    },
    {
        title: "Month3 Forecast",
        dataIndex: "month3_forecast",
        key: "month3_forecast",
        filterKey: "month3_forecast",
        visibility: true,
    },
    {
        title: "Month4 Forecast",
        dataIndex: "month4_forecast",
        key: "month4_forecast",
        filterKey: "month4_forecast",
        visibility: true,
    },
    {
        title: "Month5 Forecast",
        dataIndex: "month5_forecast",
        key: "month5_forecast",
        filterKey: "month5_forecast",
        visibility: true,
    },
    {
        title: "Month6 Forecast",
        dataIndex: "month6_forecast",
        key: "month6_forecast",
        filterKey: "month6_forecast",
        visibility: true,
    },
    {
        title: "Month7 Forecast",
        dataIndex: "month7_forecast",
        key: "month7_forecast",
        filterKey: "month7_forecast",
        visibility: true,
    },
    {
        title: "Month8 Forecast",
        dataIndex: "month8_forecast",
        key: "month8_forecast",
        filterKey: "month8_forecast",
        visibility: true,
    },
    {
        title: "Month9 Forecast",
        dataIndex: "month9_forecast",
        key: "month9_forecast",
        filterKey: "month9_forecast",
        visibility: true,
    },
    {
        title: "Month10 Forecast",
        dataIndex: "month10_forecast",
        key: "month10_forecast",
        filterKey: "month10_forecast",
        visibility: true,
    },
    {
        title: "Month11 Forecast",
        dataIndex: "month11_forecast",
        key: "month11_forecast",
        filterKey: "month11_forecast",
        visibility: true,
    },
    {
        title: "Month12 Forecast",
        dataIndex: "month12_forecast",
        key: "month12_forecast",
        filterKey: "month12_forecast",
        visibility: true,
    },
    // {
    //     title: "Yearly Forecast",
    //     dataIndex: "year_forecast",
    //     key: "year_forecast",
    //     filterKey: "year_forecast",
    //     visibility: true,
    // },
    {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        filterKey: "due_date",
        visibility: true,
    },
    {
        title: "User Updated",
        dataIndex: "user_updated",
        key: "user_updated",
        filterKey: "user_updated",
        visibility: true,
    },
    {
        title: "Last Updated",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
        filterKey: "last_updated_datetime",
        visibility: true,
    },
    {
        title: "Comments",
        dataIndex: "comment",
        key: "comment",
        filterKey: "comment",
        visibility: true,
    },

    // {
    //     title: "item_id",
    //     dataIndex: "item_id",
    //     key: "item_id",
    //     filterKey: "item_id",
    //     visibility: true,
    // },
    // {
    //     title: "sbu",
    //     dataIndex: "sbu",
    //     key: "sbu",
    //     filterKey: "sbu",
    //     visibility: true,
    // },

    // {
    //     title: "origin_service",
    //     dataIndex: "origin_service",
    //     key: "origin_service",
    //     filterKey: "origin_service",
    //     visibility: true,
    // },

    // {
    //     title: "destination_service",
    //     dataIndex: "destination_service",
    //     key: "destination_service",
    //     filterKey: "destination_service",
    //     visibility: true,
    // },

    // {
    //     title: "hazardous",
    //     dataIndex: "hazardous",
    //     key: "hazardous",
    //     filterKey: "hazardous",
    //     visibility: true,
    // },
    // {
    //     title: "heavy_flag",
    //     dataIndex: "heavy_flag",
    //     key: "heavy_flag",
    //     filterKey: "heavy_flag",
    //     visibility: true,
    // },
    // {
    //     title: "container_count",
    //     dataIndex: "container_count",
    //     key: "container_count",
    //     filterKey: "container_count",
    //     visibility: true,
    // },
]

export const oceanForecastcolumn = [
    {
        key: "forecast column",
        title: "Current forecast",
        dataIndex: "cur_forecast",
        filterKey: "cur_forecast",
        visibility: true,
        Children: <h1>a</h1>,
        style: {
            width: 300,
        },
    },
]
