import React, { useContext, useState } from "react"
import { roadContext } from ".."

function CustomTableRow({ currentForecast = 0, index, disabled, row }) {
    const [value, setValue] = useState("")
    const { page, region } = useContext(roadContext)
    return (
        <div className="forecast-row flex-row align-center">
            <div>
                <span
                    className={`cur-forecast-disp cur-forecast-disp-${page}-${index}`}
                >
                    {currentForecast.toString().slice(4)} 
                </span>
            </div>
            <div>
                <span
                    style={{
                        fontWeight: 600,
                        fontSize: "0.9rem",
                        marginRight: 10,
                    }}
                >
                    Enter forecast
                </span>
                <input
                    className={`forecast-input forecast-input-${
                        row[`${region}_lane_id`]
                    }`}
                    type="number"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default CustomTableRow
