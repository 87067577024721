export const NUM_OF_COLUMNS_PER_SET = 28
export const NUM_OF_COLUMNS = 28

export const TableHeader = [
    { label: "Lane ID", id: 1, value: true, filterkey: "lane_id" },
    { label: "Origin Region", id: 2, value: true, filterkey: "origin_region" },
    {
        label: "Origin Country",
        id: 3,
        value: true,
        filterkey: "origin_location_country",
    },
    {
        label: "Origin Location",
        id: 4,
        value: true,
        filterkey: "origin_location",
    },
    { label: "Ship Point", id: 5, value: true, filterkey: "ship_point" },
    {
        label: "Equipment",
        id: 6,
        value: true,
        filterkey: "equipment",
    },
    {
        label: "Enter Forecast",
        id: 7,
        value: true,
        filterkey: "container_count",
    },
    {
        label: "Total Forecast",
        id: 8,
        value: true,
        filterkey: "total_forecast",
    },
    {
        label: "Suggested Container Forecast",
        id: 9,
        value: true,
        filterkey: "suggested_forecast",
    },
    {
        label: "Forecasted Container Volume",
        id: 10,
        value: true,
        filterkey: "forecasted_container_volume",
    },
    { label: "BDP History", id: 11, value: true, filterkey: "bdp_history" },
    {
        label: "Sankyu History",
        id: 12,
        value: false,
        filterkey: "sankyu_history",
    },
    {
        label: "Destination Location",
        id: 13,
        value: true,
        filterkey: "destination_location",
    },

    {
        label: "Destination Country",
        id: 14,
        value: true,
        filterkey: "destination_location_country",
    },

    {
        label: "Destination Region",
        id: 15,
        value: true,
        filterkey: "destination_region",
    },
    { label: "Hazardous", id: 16, value: true, filterkey: "hazardous_flag" },

    {
        label: "Business Segment",
        id: 17,
        value: true,
        filterkey: "business_segment",
    },

    {
        label: "Last Updated By",
        id: 18,
        value: true,
        filterkey: "user_updated",
    },
    { label: "Due Date", id: 19, value: true, filterkey: "due_date" },
    {
        label: "Last Updated Date",
        id: 20,
        value: true,
        filterkey: "last_updated_datetime",
    },
    {
        label: "Demand Differential",
        id: 21,
        value: true,
        filterkey: "demand_differential",
    },
    {
        label: "Reported Container Activity",
        id: 22,
        value: false,
        filterkey: "reported_container_activity",
    },
    {
        label: "Jul 2023 Forecast",
        id: 23,
        value: false,
        filterkey: "month1_forecast"
    },
    {
        label: "Aug 2023 Forecast",
        id: 24,
        value: false,
        filterkey: "month2_forecast"
    },
    {
        label: "Sep 2023 Forecast",
        id: 25,
        value: false,
        filterkey: "month3_forecast"
    },
    {
        label: "Oct 2023 Forecast",
        id: 26,
        value: false,
        filterkey: "month4_forecast"
    },
    {
        label: "Nov 2023 Forecast",
        id: 27,
        value: false,
        filterkey: "month5_forecast"
    },
    {
        label: "Dec 2023 Forecast",
        id: 28,
        value: false,
        filterkey: "month6_forecast"
    },
    {
        label: "Jan 2024 Forecast",
        id: 29,
        value: false,
        filterkey: "month7_forecast"
    },
    {
        label: "Feb 2024 Forecast",
        id: 30,
        value: false,
        filterkey: "month8_forecast"
    },
    {
        label: "Mar 2024 Forecast",
        id: 31,
        value: false,
        filterkey: "month9_forecast"
    },
    {
        label: "Apr 2024 Forecast",
        id: 32,
        value: false,
        filterkey: "month10_forecast"
    },
    {
        label: "May 2024 Forecast",
        id: 33,
        value: false,
        filterkey: "month11_forecast"
    },
    {
        label: "Jun 2024 Forecast",
        id: 34,
        value: false,
        filterkey: "month12_forecast"
    },

]

export const AirTableHeader = [
    { label: "Origin Region", id: 1, value: true, filterkey: "origin_region" },
    {
        label: "Origin Country",
        id: 2,
        value: true,
        filterkey: "origin_country",
    },
    {
        label: "Pickup City",
        id: 3,
        value: true,
        filterkey: "pickup_city",
    },
    {
        label: "Origin Station",
        id: 4,
        value: true,
        filterkey: "origin_station",
    },
    {
        label: "Origin Airport",
        id: 5,
        value: true,
        filterkey: "origin_airport",
    },
    {
        label: "Origin Airport Location",
        id: 6,
        value: true,
        filterkey: "origin_airport_location",
    },

    {
        label: "Destination Region",
        id: 7,
        value: true,
        filterkey: "destination_region",
    },
    {
        label: "Destination Country",
        id: 8,
        value: true,
        filterkey: "destination_country",
    },

    {
        label: "Destination Airport Location",
        id: 9,
        value: true,
        filterkey: "destination_airport_location",
    },

    {
        label: "Destination Station",
        id: 10,
        value: true,
        filterkey: "destination_station",
    },
    {
        label: "Destination Airport",
        id: 11,
        value: true,
        filterkey: "destination_airport",
    },
    {
        label: "Freight Forwarder",
        id: 12,
        value: true,
        filterkey: "freight_forwarder",
    },
    {
        label: "Commodity",
        id: 13,
        value: true,
        filterkey: "commodity",
    },

    {
        label: "Demand differential",
        id: 14,
        value: true,
        filterkey: "demand_differential",
    },

    {
        label: "Forwarder Input",
        id: 15,
        value: true,
        filterkey: "forwarder input",
    },
    {
        label: "month1history",
        id: 16,
        value: true,
        filterkey: "month1_history",
    },
    {
        label: "month2history",
        id: 17,
        value: true,
        filterkey: "month2_history",
    },
    {
        label: "month3history",
        id: 18,
        value: true,
        filterkey: "month3_history",
    },
    {
        label: "month4history",
        id: 19,
        value: true,
        filterkey: "month4_history",
    },
    {
        label: "month5history",
        id: 20,
        value: true,
        filterkey: "month5_history",
    },
    {
        label: "month6history",
        id: 21,
        value: true,
        filterkey: "month6_history",
    },
    {
        label: "Six Months History Total",
        id: 22,
        value: true,
        filterkey: "six_months_history_total",
    },
    {
        label: "Six Months History Avg",
        id: 23,
        value: true,
        filterkey: "six_months_history_avg",
    },

    {
        label: "month1forecast",
        id: 24,
        value: true,
        filterkey: "month1_forecast",
    },
    {
        label: "month2forecast",
        id: 25,
        value: true,
        filterkey: "month2_forecast",
    },
    {
        label: "month3forecast",
        id: 26,
        value: true,
        filterkey: "month3_forecast",
    },
    {
        label: "month4forecast",
        id: 27,
        value: true,
        filterkey: "month4_forecast",
    },
    {
        label: "month5forecast",
        id: 28,
        value: true,
        filterkey: "month5_forecast",
    },
    {
        label: "month6forecast",
        id: 29,
        value: true,
        filterkey: "month6_forecast",
    },
    {
        label: "month7forecast",
        id: 30,
        value: true,
        filterkey: "month7_forecast",
    },
    {
        label: "month8forecast",
        id: 31,
        value: true,
        filterkey: "month8_forecast",
    },
    {
        label: "month9forecast",
        id: 32,
        value: true,
        filterkey: "month9_forecast",
    },
    {
        label: "month10forecast",
        id: 33,
        value: true,
        filterkey: "month10_forecast",
    },
    {
        label: "month11forecast",
        id: 34,
        value: true,
        filterkey: "month11_forecast",
    },
    {
        label: "month12forecast",
        id: 35,
        value: true,
        filterkey: "month12_forecast",
    },

    {
        label: "Yearly Forecast",
        id: 36,
        value: true,
        filterkey: "year_forecast",
    },
    {
        label: "User updated",
        id: 37,
        value: true,
        filterkey: "user_updated",
    },

    {
        label: "Last Updated Time",
        id: 38,
        value: true,
        filterkey: "last_updated_datetime",
    },
    {
        label: "Comments",
        id: 39,
        value: true,
        filterkey: "dupont_input",
    },
    {
        label: "Due Date",
        id: 40,
        value: true,
        filterkey: "due_date",
    },
    {
        label: "Current forecast",
        id: 41,
        value: true,
        filterkey: "current_forecast",
    },
]

export const AirCampaignTableHeader = [
    {
        label: "Origin Region",
    },
    {
        label: "Origin Country",
    },
    {
        label: "Origin City",
    },
    {
        label: "Destination Airport",
    },
    {
        label: "Destination City",
    },
    {
        label: "Campaign Start Date",
    },
    {
        label: "Campaign End Date",
    },
    {
        label: "Campaign Weight",
    },
]

export const month_dict = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
}
export const DateFormat = (inputdate) => {
    let date = new Date(inputdate)
    if (!isNaN(date.getTime())) {
        return (
            date.getMonth() +
            1 +
            "-" +
            date.getDate() +
            "-" +
            date.getFullYear()
        )
    }
}

// TODO add in logic to create dynamic headers - rename these to generic for months
export const OceanMonthTableHeader = [
    { label: "Lane ID", id: 1, value: true, filterkey: "lane_id" },
    { label: "Origin Region", id: 2, value: true, filterkey: "origin_region" },
    {
        label: "Origin Country",
        id: 3,
        value: true,
        filterkey: "origin_location_country",
    },
    {
        label: "Origin Location",
        id: 4,
        value: true,
        filterkey: "origin_location",
    },
    { label: "Ship Point", id: 5, value: true, filterkey: "ship_point" },
    {
        label: "Equipment",
        id: 6,
        value: true,
        filterkey: "equipment",
    },

    {
        label: "Destination Location",
        id: 7,
        value: true,
        filterkey: "destination_location",
    },

    {
        label: "Destination Country",
        id: 8,
        value: true,
        filterkey: "destination_location_country",
    },

    {
        label: "Destination Region",
        id: 9,
        value: true,
        filterkey: "destination_region",
    },

    { label: "Hazardous", id: 10, value: true, filterkey: "hazardous_flag" },

    {
        label: "Forecasted Yearly Container Volume",
        id: 11,
        value: true,
        filterkey: "forecasted_container_volume",
    },
    {
        label: "BDP 1 Year History",
        id: 12,
        value: true,
        filterkey: "bdp_history",
    },
    {
        label: "AP 1 Year Outbound History",
        id: 13,
        value: true,
        filterkey: "ap_outbound_history",
    },

    {
        label: "ILP Suggested Yearly Container Forecast",
        id: 14,
        value: true,
        filterkey: "suggested_yearly_forecast",
    },
    {
        label: "April 2023 Forecast",
        id: 15,
        value: true,
        filterkey: "month1_forecast",
    },
    {
        label: "May 2023 Forecast",
        id: 16,
        value: true,
        filterkey: "month2_forecast",
    },
    {
        label: "June 2023 Forecast",
        id: 17,
        value: true,
        filterkey: "month3_forecast",
    },
    {
        label: "July 2023 Forecast",
        id: 18,
        value: true,
        filterkey: "month4_forecast",
    },
    {
        label: "Aug 2023 Forecast",
        id: 19,
        value: true,
        filterkey: "month5_forecast",
    },
    {
        label: "Sep 2023 Forecast",
        id: 20,
        value: true,
        filterkey: "month6_forecast",
    },

    {
        label: "Six Months Forecast Total",
        id: 21,
        value: true,
        filterkey: "six_month_forecast",
    },
    {
        label: "Business Segment",
        id: 22,
        value: true,
        filterkey: "business_segment",
    },

    {
        label: "Last Updated By",
        id: 23,
        value: true,
        filterkey: "user_updated",
    },
    { label: "Due Date", id: 24, value: true, filterkey: "due_date" },
    {
        label: "Last Updated Date",
        id: 25,
        value: true,
        filterkey: "last_updated_datetime",
    },
    {
        label: "Comment",
        id: 26,
        value: true,
        filterkey: "comments",
    },
    {
        label: "Demand Differential",
        id: 27,
        value: true,
        filterkey: "demand_differential",
    },
]
