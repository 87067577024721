import {
    UPDATE_DUPLICATE_OCEAN_DATA,
    UPDATE_FILTERED_OCEAN_DATA,
    UPDATE_OCEAN_COLUMNS,
    UPDATE_OCEAN_DATA,
} from "../types/oceanActionTypes"

const ocean = {
    data: null,
    duplicateData: null,
    filteredData: null,
    columns: null,
}

const oceanReducer = (state = ocean, action) => {
    switch (action.type) {
        case UPDATE_OCEAN_DATA:
            return { ...state, data: action.payload }
        case UPDATE_OCEAN_COLUMNS:
            return { ...state, columns: action.payload }
        case UPDATE_FILTERED_OCEAN_DATA:
            return { ...state, filteredData: action.payload }
        case UPDATE_DUPLICATE_OCEAN_DATA:
            return { ...state, duplicateData: action.payload }
        default:
            return state
    }
}
export default oceanReducer
