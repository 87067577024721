export const koreaRoadColumns = [
    {
        title: "Plant",
        dataIndex: "plant_code",
        key: "plant_code",
        filterKey: "plant_code",
        visibility: true,
    },
    {
        title: "Business Segment",
        dataIndex: "business_segment",
        key: "business_segment",
        filterKey: "business_segment",
        visibility: true,
    },
    {
        title: "Origin Location",
        dataIndex: "origin_location",
        key: "origin_location",
        filterKey: "origin_location",
        visibility: true,
    },
    {
        title: "Destination Location",
        dataIndex: "destination_location",
        key: "destination_location",
        filterKey: "destination_location",
        visibility: true,
    },
    {
        title: "Hazardous",
        dataIndex: "hazardous",
        key: "hazardous",
        filterKey: "hazardous",
        visibility: true,
    },
    {
        title: "Temperature",
        dataIndex: "temperature",
        key: "temperature",
        filterKey: "temperature",
        visibility: true,
    },
    {
        title: "Truck Size",
        dataIndex: "truck_size",
        key: "truck_size",
        filterKey: "truck_size",
        visibility: true,
    },
    {
        title: "Month1 History",
        dataIndex: "month1_history",
        key: "month1_history",
        filterKey: "month1_history",
        visibility: true,
    },
    {
        title: "Month2 History",
        dataIndex: "month2_history",
        key: "month2_history",
        filterKey: "month2_history",
        visibility: true,
    },
    {
        title: "Month3 History",
        dataIndex: "month3_history",
        key: "month3_history",
        filterKey: "month3_history",
        visibility: true,
    },
    {
        title: "Month4 History",
        dataIndex: "month4_history",
        key: "month4_history",
        filterKey: "month4_history",
        visibility: true,
    },
    {
        title: "Month5 History",
        dataIndex: "month5_history",
        key: "month5_history",
        filterKey: "month5_history",
        visibility: true,
    },
    {
        title: "Month6 History",
        dataIndex: "month6_history",
        key: "month6_history",
        filterKey: "month6_history",
        visibility: true,
    },
    {
        title: "Six Months History Total",
        dataIndex: "six_months_history_total",
        key: "six_months_history_total",
        filterKey: "six_months_history_total",
        visibility: true,
    },
    {
        title: "Annual Forecast",
        dataIndex: "annual_forecast",
        key: "annual_forecast",
        filterKey: "annual_forecast",
        visibility: true,
    },
    {
        title: "Month1 Forecast",
        dataIndex: "month1_forecast",
        key: "month1_forecast",
        filterKey: "month1_forecast",
        visibility: true,
    },
    {
        title: "Month2 Forecast",
        dataIndex: "month2_forecast",
        key: "month2_forecast",
        filterKey: "month2_forecast",
        visibility: true,
    },
    {
        title: "Month3 Forecast",
        dataIndex: "month3_forecast",
        key: "month3_forecast",
        filterKey: "month3_forecast",
        visibility: true,
    },
    {
        title: "Month4 Forecast",
        dataIndex: "month4_forecast",
        key: "month4_forecast",
        filterKey: "month4_forecast",
        visibility: true,
    },
    {
        title: "Month5 Forecast",
        dataIndex: "month5_forecast",
        key: "month5_forecast",
        filterKey: "month5_forecast",
        visibility: true,
    },
    {
        title: "Month6 Forecast",
        dataIndex: "month6_forecast",
        key: "month6_forecast",
        filterKey: "month6_forecast",
        visibility: true,
    },
    {
        title: "Month7 Forecast",
        dataIndex: "month7_forecast",
        key: "month7_forecast",
        filterKey: "month7_forecast",
        visibility: true,
    },
    {
        title: "Month8 Forecast",
        dataIndex: "month8_forecast",
        key: "month8_forecast",
        filterKey: "month8_forecast",
        visibility: true,
    },
    {
        title: "Month9 Forecast",
        dataIndex: "month9_forecast",
        key: "month9_forecast",
        filterKey: "month9_forecast",
        visibility: true,
    },
    {
        title: "Month10 Forecast",
        dataIndex: "month10_forecast",
        key: "month10_forecast",
        filterKey: "month10_forecast",
        visibility: true,
    },
    {
        title: "Month11 Forecast",
        dataIndex: "month11_forecast",
        key: "month11_forecast",
        filterKey: "month11_forecast",
        visibility: true,
    },
    {
        title: "Month12 Forecast",
        dataIndex: "month12_forecast",
        key: "month12_forecast",
        filterKey: "month12_forecast",
        visibility: true,
    },
    {
        title: "Last Updated Datetime",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
        filterKey: "last_updated_datetime",
        visibility: true,
    },
    {
        title: "User Updated",
        dataIndex: "user_updated",
        key: "user_updated",
        filterKey: "user_updated",
        visibility: true,
    },
    // {
    //     title: "First Year",
    //     dataIndex: "first_year",
    //     key: "first_year",
    //     filterKey: "first_year",
    //     visibility: true,
    // },
    // {
    //     title: "First Month",
    //     dataIndex: "first_month",
    //     key: "first_month",
    //     filterKey: "first_month",
    //     visibility: true,
    // },
    {
        title: "Freight Forwarder",
        dataIndex: "freight_forwarder",
        key: "freight_forwarder",
        filterKey: "freight_forwarder",
        visibility: true,
    },
    {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        filterKey: "due_date",
        visibility: true,
    },
    {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        filterKey: "comments",
        visibility: true,
    },
]

export const roadForecastcolumn = [
    {
        key: "forecast column",
        title: "Current forecast",
        dataIndex: "cur_forecast",
        filterKey: "cur_forecast",
        visibility: true,
        Children: <h1>a</h1>,
        style: {
            width: 300,
        },
    },
]
