import { useEffect, useState, createContext, useRef } from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { message, Modal } from "antd"
import { useMsal } from "@azure/msal-react"
import axios from "axios"
import { getAccessToken } from "../Utils/helperFunctions"
import PageBody from "./Components/PageBody"
import { oceanColumns, oceanForecastcolumn } from "./Utils/columns"
import OceanTableHeader from "./Components/TableHeader"
import {
    getOceanColumnCookies,
    getOceanFirstMonth,
    updateOceanColumnCookies,
    updateOceanFirstMonth,
} from "./Utils/updateCookies"
import { useDispatch, useSelector } from "react-redux"
import {
    getOceanForecastData,
    updateDuplicateOceanData,
    updateOceanColumns,
    updateOceanData,
} from "../Redux/actions/oceanActions"
import { getAppropriateMonthNames } from "./Utils/dynamicMonths"
import { month_dict } from "../Utils/tableutils"
import { getForecastColumnsList } from "../Ocean/Utils/dynamicMonths"

export const oceanContext = createContext()

function Ocean() {
    const { instance, inProgress, accounts } = useMsal()
    // const { config } = useContext(AppContext)
    const [currentForecastMonth, setCurrentForecastMonth] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const [config, setConfig] = useState(null)
    const forecastPickerRef = useRef()
    const [refreshForecast, setRefreshForecast] = useState(true)
    const [filterRef, setFilterRef] = useState(null)
    const [forecastColumns, setForecastColumns] = useState(null)
    const [currentForecastMonthStore, setCurrentForecastMonthStore] =
        useState(null)

    const [messageApi, contextHolder] = message.useMessage()

    const dispatch = useDispatch()
    const { data, columns } = useSelector((state) => state.ocean)
    /**
        * Whenever there's a change in firstmonth value (response from API), 
                we are recalculating month names.
        * firstmonth value indicates the first month name in the data.
        * in data, we get month1_history, month2_history and so on.
        * but for user convenience, we are generating appropriate month names(using ) using this API response.
        * for ex, first_month: 6, first_year: 2022 then month1_history will be renamed as May 2022 History. 
        */
    const getFirstMonth = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/firstmonth`,
                config
            )
            .then(({ data }) => {
                let cached = getOceanFirstMonth()
                let dynamicMonths = getAppropriateMonthNames(
                    data.first_month,
                    data.first_year
                )

                if (isNaN(cached.first_month)) {
                    updateOceanFirstMonth(data)

                    dispatch(updateOceanColumns(dynamicMonths))
                    updateOceanColumnCookies(
                        getAppropriateMonthNames(
                            data.first_month,
                            data.first_year
                        )
                    )
                    setForecastColumns(getForecastColumnsList(dynamicMonths))
                } else if (
                    cached.first_month !== data.first_month ||
                    cached.first_year !== data.first_year
                ) {
                    dispatch(updateOceanColumns(dynamicMonths))
                    updateOceanColumnCookies(dynamicMonths)
                    updateOceanFirstMonth(data)
                }
                setForecastColumns(getForecastColumnsList(dynamicMonths))
            })
            .catch((err) => err.message)
    }

    useEffect(() => {
        const getAccessTokenHandler = async () => {
            const accessToken = await getAccessToken(
                instance,
                inProgress,
                accounts
            )
            if (accessToken) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken.access}`,
                        "Content-Type": "application/json",
                        id: `${accessToken.id}`,
                    },
                }
                setConfig(config)
            }
        }
        getAccessTokenHandler()
    }, [instance, accounts, inProgress])

    useEffect(() => {
        const initializer = async () => {
            if (config) {
                await getOceanData().then(() => {
                    getFirstMonth()
                    let oceanColumnsCookies = getOceanColumnCookies()
                    if (oceanColumnsCookies) {
                        dispatch(updateOceanColumns(oceanColumnsCookies))
                    } else {
                        dispatch(
                            updateOceanColumns([
                                ...oceanColumns,
                                ...oceanForecastcolumn,
                            ])
                        )
                    }
                })
            }
        }
        initializer()
    }, [config])

    const getOceanData = async () => {
        axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/forecast`,
                config
            )
            .then(({ data }) => {
                dispatch(updateOceanData(data))
                dispatch(updateDuplicateOceanData(data))
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        const inputs = document.querySelectorAll(".forecast-input")
        inputs.forEach((input) => {
            input.value = ""
        })

        const forecastSpans = document.querySelectorAll(".cur-forecast-disp")
        forecastSpans.forEach((item) => {
            item.innerHTML = "0"
        })

        if (currentForecastMonth !== "") {
            // let month = parseInt(currentForecastMonth.slice(5, 7))
            // let year = parseInt(currentForecastMonth.slice(0, 4))
            let column = columns.filter((column) => {
                return column.dataIndex === currentForecastMonth
            })
            // if (
            //     column.length === 0 ||
            //     column === undefined ||
            //     !column[0].dataIndex
            // ) {
            //     message.error("Please choose a valid month")
            //     return
            // }
            const forecastSpans =
                document.querySelectorAll(".cur-forecast-disp")

            forecastSpans.forEach((item) => {
                const numberRegex = /\d+/g
                const index = parseInt(item.classList[1].match(numberRegex)[0])
                item.innerHTML = data[index][column[0].dataIndex] || "0"
            })
            const forecastSubmitBtn = document.getElementById(
                "ocean-forecast-submit-btn"
            )
            inputs.forEach((input) => {
                input.value = ""
                input.addEventListener("keyup", (e) => {
                    if (e.keyCode === 13) {
                        forecastSubmitBtn.click()
                    }
                })
            })
        }
    }, [currentForecastMonth, currentPage, data])

    const submitNewForecast = async () => {
        if (currentForecastMonth === "") {
            message.warning("Please choose a month :)")
            return
        }
        const inputs = document.querySelectorAll(".forecast-input")
        const numRegEx = /\d+/g
        let posts = []

        // let monthAndYear = currentForecastMonth.match(numRegEx)
        // let monthName = month_dict[parseInt(monthAndYear[1])]
        let column = columns.filter(
            (column) => column.dataIndex === currentForecastMonth
        )[0]
        inputs.forEach((input) => {
            let ocean_lane_id = parseInt(input.classList[1].match(numRegEx))
            if (input.value !== "") {
                posts.push(
                    currentForecastMonth !== "suggested_forecast"
                        ? {
                            ocean_lane_id: ocean_lane_id,
                            container_count: parseInt(input.value),
                            forecast_month: parseInt(
                                column.dataIndex.match(numRegEx)[0]
                            ),
                        }
                        : {
                            ocean_lane_id: ocean_lane_id,
                            container_count: parseInt(input.value),
                        }
                )
            }
        })
        if (posts.length === 0) {
            message.warning("please enter valid forecast values")
            return
        }
        if (currentForecastMonth === "suggested_forecast") {
            Modal.confirm({
                title: "Alert",
                icon: <ExclamationCircleOutlined />,
                content: `You're submitting the Annual Forecast Value. This will overwrite all corresponding Monthly Forecast Values by dispersing this value equally among them. Are you sure you want to submit?`,
                okText: "Submit",
                cancelText: "Cancel",
                onOk: async () => {
                    messageApi.open({
                        key: "updatingForecast",
                        type: "loading",
                        content: "submitting new forecasts...",
                        duration: 15,
                    })
                    const forecastSubmitBtn = document.getElementById(
                        "ocean-forecast-submit-btn"
                    )
                    forecastSubmitBtn.disabled = true
                    setTimeout(() => {
                        forecastSubmitBtn.disabled = false
                    }, 5000)
                    await axios
                        .post(
                            `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/${currentForecastMonth === "suggested_forecast"
                                ? "forecast"
                                : "monthlyforecast"
                            }`,
                            { posts },
                            config
                        )
                        .then((res) => {
                            messageApi.open({
                                key: "updatingForecast",
                                type: "success",
                                content: res.data,
                            })
                            messageApi.open({
                                // key: "updatingForecast",
                                type: "loading",
                                content: "refreshing data...",
                            })
                            dispatch(getOceanForecastData(config))
                            setRefreshForecast((prevState) => !prevState)
                        })
                        .catch((err) => {
                            messageApi.open({
                                key: "updatingForecast",
                                type: "error",
                                content: err.message,
                            })
                        })
                },
        })
        }

        else {
            messageApi.open({
                key: "updatingForecast",
                type: "loading",
                content: "submitting new forecasts...",
                duration: 15,
            })
            const forecastSubmitBtn = document.getElementById(
                "ocean-forecast-submit-btn"
            )
            forecastSubmitBtn.disabled = true
            setTimeout(() => {
                forecastSubmitBtn.disabled = false
            }, 5000)
            await axios
                .post(
                    `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/${currentForecastMonth === "suggested_forecast"
                        ? "forecast"
                        : "monthlyforecast"
                    }`,
                    { posts },
                    config
                )
                .then((res) => {
                    messageApi.open({
                        key: "updatingForecast",
                        type: "success",
                        content: res.data,
                    })
                    messageApi.open({
                        // key: "updatingForecast",
                        type: "loading",
                        content: "refreshing data...",
                    })
                    dispatch(getOceanForecastData(config))
                    setRefreshForecast((prevState) => !prevState)
                })
                .catch((err) => {
                    messageApi.open({
                        key: "updatingForecast",
                        type: "error",
                        content: err.message,
                    })
                })
        }
    }

    return (
        <div className="road-global-container">
            {contextHolder}
            <oceanContext.Provider
                value={{
                    setCurrentForecastMonth,
                    currentForecastMonth,
                    page: "ocean",
                    currentPage,
                    setCurrentPage,
                    pageSize,
                    setPageSize,
                    config,
                    filterRef,
                    setFilterRef,
                    forecastPickerRef,
                    forecastColumns,
                    currentForecastMonthStore,
                    setCurrentForecastMonthStore,
                }}
            >
                <PageBody
                    tableHeader={
                        <OceanTableHeader
                            submitNewForecast={submitNewForecast}
                        // handleUpload={handleUpload}
                        />
                    }
                    page="ocean"
                    reportType={"Monthly"}
                    columns={columns}
                    data={data}
                    forecastColumn={oceanForecastcolumn}
                />
            </oceanContext.Provider>
        </div>
    )
}

export default Ocean
