import { Divider, Pagination, Switch } from "antd"
import React, { useContext, useState } from "react"
import CustomTable from "./CustomTable"
import Loading from "./Loading"
import SideBar from "./SideBar"
import { airContext } from ".."

function PageBody({
    columns,
    data,
    tableHeader,
    page,
    forecastColumn,
    reportType,
    sideBarYear,
    sideBarQuarter,
}) {
    const { currentPage, setCurrentPage, pageSize, setPageSize } =
        useContext(airContext)
    const [switchedOn, setSwitchedOn] = useState(true)

    const onPageChange = (page) => {
        setCurrentPage(page)
    }
    if (columns)
        return (
            <div
                className="flex-row large-padding"
                style={{ paddingTop: "20px", marginTop: "-30px" }}
            >
                <SideBar
                    columns={columns}
                    page={page}
                    quarter={sideBarQuarter}
                    year={sideBarYear}
                    forecastColumn={forecastColumn}
                />
                <Divider
                    type="vertical"
                    style={{
                        marginTop: "15px",
                        height: "165vh",
                        marginLeft: "-10px",
                        marginRight: "15px",
                    }}
                />
                <div className="page-container flex-col">
                    <div className="page-header flex-col">
                        <span
                            className="first gray-text"
                            style={{ marginBottom: "1rem", marginTop: "7px" }}
                        >
                            Forecast Planning Manager
                        </span>
                        <span
                            className="second blue-text"
                            style={{ marginBottom: "0.5rem" }}
                        >
                            {reportType}
                        </span>
                    </div>
                    <div className="road-table-container-outer">
                        {tableHeader}
                        <div className="flex-row tables">
                            {data ? (
                                <CustomTable
                                    entireData={data}
                                    trimmedData={
                                        switchedOn
                                            ? data.slice(
                                                  (currentPage - 1) * pageSize,
                                                  currentPage * pageSize
                                              )
                                            : data
                                    }
                                    columns={columns}
                                    forecastColumn={forecastColumn}
                                    page={page}
                                />
                            ) : (
                                <div className="width-100 flex-row align-center justify-center">
                                    <Loading />
                                </div>
                            )}
                        </div>
                        {data ? (
                            <div className="page-footer">
                                <div className="flex-row align-center ">
                                    <span style={{ marginRight: 10 }}>
                                        View All{" "}
                                    </span>
                                    <Switch
                                        onChange={() => {
                                            setSwitchedOn(
                                                (prevState) => !prevState
                                            )
                                        }}
                                    />
                                </div>
                                {switchedOn && (
                                    <Pagination
                                        total={data.length}
                                        showQuickJumper
                                        current={currentPage}
                                        onChange={onPageChange}
                                        responsive={true}
                                        pageSize={pageSize}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        )
}

export default PageBody
