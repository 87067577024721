import React from "react"

const IconText = ({ text, Icon }) => {
    return (
        <div className="flex-row align-center padding-y-medium">
            <Icon style={{ marginRight: "7px" }} />
            {text}
        </div>
    )
}

export default IconText
