import Cookies from "universal-cookie"

//setting columns to the localStorage as cookies, to store column view
export const updateOceanColumnCookies = (columns) => {
    localStorage.setItem("EI_OCEAN_Columns", JSON.stringify(columns))
}
// returning column cookies from the localStorage
export const getOceanColumnCookies = () => {
    return JSON.parse(localStorage.getItem("EI_OCEAN_Columns"))
}

const cookies = new Cookies()
export const updateOceanFirstMonth = (data) => {
    cookies.set("oceanFirstMonth", data.first_month)
    cookies.set("oceanFirstYear", data.first_year)
}
export const getOceanFirstMonth = () => {
    return {
        first_month: parseInt(cookies.get("oceanFirstMonth")),
        first_year: parseInt(cookies.get("oceanFirstYear")),
    }
}
export const updateOceanColumnFilterCookies = (filters) => {
    localStorage.setItem("oceanColumnFilters", JSON.stringify(filters))
}

export const getOceanColumnFilterCookies = () => {
    return JSON.parse(localStorage.getItem("oceanColumnFilters"))
}
export const removeOceanColumnFilterCookies = () => {
    localStorage.removeItem("oceanColumnFilters")
}
