import { AutoComplete, DatePicker, Form, Modal, Upload, message } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { IconButton } from "../../Components/Button"
import { Button } from "react-bootstrap"
import { CSVLink } from "react-csv"
import {
    UploadOutlined,
    PlusOutlined,
    LoadingOutlined,
    ExportOutlined,
    CheckOutlined,
    DeleteOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { airContext } from ".."
import axios from "axios"
import {
    getAirForecastData,
    updateAirData,
} from "../../Redux/actions/airActions"
import Swal from "sweetalert2"
import { removeAirColumnFilterCookies } from "../Utils/updateCookies"

const AirTableHeader = ({ submitNewForecast }) => {
    const [fileList, setFileList] = useState([])
    const [messageApi, contextHolder] = message.useMessage()

    const [uploading, setUploading] = useState(false)
    const [exportData, setExportData] = useState([])
    const csvRef = useRef()
    const { config, filterRef } = useContext(airContext)
    const { duplicateData } = useSelector((state) => state.air)

    const [addLaneForm] = Form.useForm()
    const [newLaneAddLoading, setNewLaneAddLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const { confirm } = Modal
    const dispatch = useDispatch()
    const { data } = useSelector((state) => state.air)

    const [originRegionFilter, setOriginRegionFilter] = useState([])
    const [originCountryFilter, setOriginCountryFilter] = useState([])
    const [pickupCityFilter, setPickupCityFilter] = useState([])
    const [originStationFilter, setOriginStationFilter] = useState([])
    const [destinationStationFilter, setDestinationStationFilter] = useState([])
    const [destinationCountryFilter, setDestinationCountryFilter] = useState([])
    const [commoditiesFilter, setCommoditiesFilter] = useState([])
    const [businessUnitFilter, setBusinessUnitFilter] = useState([])
    const [freightForwarderFilter, setFreightForwarderFilter] = useState([])
    const [businessSegmentFilter, setBusinessSegmentFilter] = useState([])

    const exportAirData = async () => {
        await axios
            .get(`${process.env.REACT_APP_FAST_API_APP_URL}/air/export`, config)
            .then(({ data }) => {
                setExportData(data)
                setTimeout(() => {
                    csvRef.current.link.click()
                }, 100)
                setTimeout(() => {
                    message.success("file exported successfully!!")
                }, 200)
            })
            .catch((err) => console.log(err))
    }

    const updateDueDate = async (date) => {
        if (date === "") return
        confirm({
            title: "Are you sure you want to update Due date?",
            async onOk() {
                try {
                    await axios
                        .post(
                            `${process.env.REACT_APP_FAST_API_APP_URL}/air/globalduedate`,
                            { due_date: date },
                            config
                        )
                        .then((response) => {
                            message.success(response.data)
                            dispatch(getAirForecastData(config))
                            message.loading("Updating table data!!", 1.5)
                        })
                        .catch((err) => message.error(err.message))
                } catch (e) {
                    return console.log("Oops errors!")
                }
            },
            onCancel() { },
        })
    }
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file])
            return false
        },
        fileList,
    }
    const handleUpload = async (fileList) => {
        console.log(fileList)
        let formData = new FormData()
        fileList.forEach((file) => {
            formData.append("uploadFiles", file.originFileObj)
        })
        let messageKey = "UPLOAD_FILES"
        Swal.fire({
            icon: "info",
            title: `Uploading files, Please hang on...`,
            showConfirmButton: false,
            timer: 240000,
            timerProgressBar: true,
        })
        // messageApi.open({
        //     key: messageKey,
        //     type: "loading",
        //     content: "Uploading files, please hang on...",
        //     duration: 300,
        // })
        await axios
            .patch(
                `${process.env.REACT_APP_FAST_API_APP_URL}/air/forecast`,
                formData,
                config
            )
            .then((response) => {
                console.log(response)
                Swal.close()
                messageApi.open({
                    key: messageKey,
                    type: "success",
                    // content: "Files uploaded successfully!",
                    content: response.data,
                    duration: 5,
                })
                setFileList([])
            })
            .catch((err) => {
                Swal.close()
                messageApi.open({
                    type: "error",
                    key: messageKey,
                    content: err.message,
                    duration: 3,
                })
            })
    }

    useEffect(() => {
        if (data && data.length) {
            if (
                pickupCityFilter.length === 0 &&
                originStationFilter.length === 0 &&
                originCountryFilter.length === 0 &&
                originRegionFilter.length === 0
            ) {
                let origin_region = new Set()
                let origin_country = new Set()
                let origin_station = new Set()
                let pickup_city = new Set()
                let destination_station = new Set()
                let destination_country = new Set()
                let commodity = new Set()
                let business_unit = new Set()
                let business_segment = new Set()
                let freight_forwarder = new Set()
                if (data !== null && data.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                        if (
                            data[i].origin_region !== null &&
                            data[i].origin_region !== undefined &&
                            data[i].origin_region !== "" &&
                            data[i].origin_region !== "na"
                        ) {
                            origin_region.add(data[i].origin_region)
                        }
                        if (
                            data[i].origin_country !== null &&
                            data[i].origin_country !== undefined &&
                            data[i].origin_country !== "" &&
                            data[i].origin_country !== "NA" &&
                            data[i].origin_country !== "na"
                        ) {
                            origin_country.add(data[i].origin_country)
                        }
                        if (
                            data[i].origin_station !== null &&
                            data[i].origin_station !== undefined &&
                            data[i].origin_station !== "" &&
                            data[i].origin_station !== "NA" &&
                            data[i].origin_station !== "na"
                        ) {
                            origin_station.add(data[i].origin_station)
                        }
                        if (
                            data[i].pickup_city !== null &&
                            data[i].pickup_city !== undefined &&
                            data[i].pickup_city !== "" &&
                            data[i].pickup_city !== "NA" &&
                            data[i].pickup_city !== "na"
                        ) {
                            pickup_city.add(data[i].pickup_city)
                        }
                        if (
                            data[i].destination_station !== null &&
                            data[i].destination_station !== undefined &&
                            data[i].destination_station !== "" &&
                            data[i].destination_station !== "NA" &&
                            data[i].destination_station !== "na"
                        ) {
                            destination_station.add(data[i].destination_station)
                        }
                        if (
                            data[i].destination_country !== null &&
                            data[i].destination_country !== undefined &&
                            data[i].destination_country !== "" &&
                            data[i].destination_country !== "NA" &&
                            data[i].destination_country !== "na"
                        ) {
                            destination_country.add(data[i].destination_country)
                        }
                        if (
                            data[i].commodity !== null &&
                            data[i].commodity !== undefined &&
                            data[i].commodity !== "" &&
                            data[i].commodity !== "NA" &&
                            data[i].commodity !== "na"
                        ) {
                            commodity.add(data[i].commodity)
                        }
                        if (
                            data[i].freight_forwarder !== null &&
                            data[i].freight_forwarder !== undefined &&
                            data[i].freight_forwarder !== "" &&
                            data[i].freight_forwarder !== "NA" &&
                            data[i].freight_forwarder !== "na"
                        ) {
                            freight_forwarder.add(data[i].freight_forwarder)
                        }
                        if (
                            data[i].business_unit !== null &&
                            data[i].business_unit !== undefined &&
                            data[i].business_unit !== "" &&
                            data[i].business_unit !== "NA" &&
                            data[i].business_unit !== "na"
                        ) {
                            business_unit.add(data[i].business_unit)
                        }
                        if (
                            data[i].business_segment !== null &&
                            data[i].business_segment !== undefined &&
                            data[i].business_segment !== "" &&
                            data[i].business_segment !== "NA" &&
                            data[i].business_segment !== "na"
                        ) {
                            business_segment.add(data[i].business_segment)
                        }
                    }

                    setOriginRegionFilter(
                        Array.from(origin_region)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setOriginCountryFilter(
                        Array.from(origin_country)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setPickupCityFilter(
                        Array.from(pickup_city)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setOriginStationFilter(
                        Array.from(origin_station)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setOriginStationFilter(
                        Array.from(origin_station)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setDestinationStationFilter(
                        Array.from(destination_station)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setDestinationCountryFilter(
                        Array.from(destination_country)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                    setCommoditiesFilter(
                        Array.from(commodity)
                            .map((item) => ({
                                value: item[0].toUpperCase() + item.slice(1),
                            }))
                            .sort((a, b) => (a.value < b.value ? -1 : 1))
                    )
                }
                setBusinessSegmentFilter(
                    Array.from(business_segment)
                        .map((item) => ({
                            value: item[0].toUpperCase() + item.slice(1),
                        }))
                        .sort((a, b) => (a.value < b.value ? -1 : 1))
                )
                setBusinessUnitFilter(
                    Array.from(business_unit)
                        .map((item) => ({
                            value: item[0].toUpperCase() + item.slice(1),
                        }))
                        .sort((a, b) => (a.value < b.value ? -1 : 1))
                )
                setFreightForwarderFilter(
                    Array.from(freight_forwarder)
                        .map((item) => ({
                            value: item[0].toUpperCase() + item.slice(1),
                        }))
                        .sort((a, b) => (a.value < b.value ? -1 : 1))
                )
            }
        }
    }, [data])

    const handleAddNewLane = async () => {
        // adding a new Lane
        const values = addLaneForm.getFieldsValue()
        console.log(values)
        setNewLaneAddLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_FAST_API_APP_URL}/air/addlane`,
                values,
                config
            )
            .then(async (response) => {
                console.log(response.data)
                message.success(response.data)
                setModalOpen(false)
                setNewLaneAddLoading(false)
                addLaneForm.resetFields()
            })
            .catch((err) => {
                message.error(err.message)
                setModalOpen(false)
                setNewLaneAddLoading(false)
            })
    }

    return (
        <div className="flex-row align-center space-between mb-medium  ">
            {contextHolder}
            <Modal
                title="Add new lane"
                style={{}}
                centered
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                footer={[]}
            >
                <Form
                    form={addLaneForm}
                    onFinish={handleAddNewLane}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        name="business_unit"
                        label="Sub Business Unit"
                        rules={[{ required: true }]}
                    >
                        {/* <Input placeholder="Origin region" /> */}
                        <AutoComplete
                            placeholder="SBU"
                            onChange={() =>
                                console.log(
                                    addLaneForm.getFieldValue(
                                        "business_unit"
                                    )
                                )
                            }
                            filterOption={(
                                inputValue,
                                option
                            ) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(
                                        inputValue.toUpperCase()
                                    ) !== -1
                            }
                            options={businessUnitFilter}
                        />
                    </Form.Item>

                    <Form.Item
                        name="business_segment"
                        label="Business Segment"
                        rules={[{ required: true }]}
                    >
                        {/* <Input placeholder="Origin region" /> */}
                        <AutoComplete
                            placeholder="Business Segment"
                            onChange={() =>
                                console.log(
                                    addLaneForm.getFieldValue(
                                        "business_segment"
                                    )
                                )
                            }
                            filterOption={(
                                inputValue,
                                option
                            ) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(
                                        inputValue.toUpperCase()
                                    ) !== -1
                            }
                            options={businessSegmentFilter}
                        />
                    </Form.Item>
                    <Form.Item
                        name="origin_region"
                        label="Origin Region"
                        rules={[{ required: true }]}
                    >
                        {/* <Input placeholder="Origin region" /> */}
                        <AutoComplete
                            placeholder="Origin region"
                            onChange={() =>
                                console.log(
                                    addLaneForm.getFieldValue("origin_region")
                                )
                            }
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                            options={originRegionFilter}
                        />
                    </Form.Item>
                    <Form.Item
                        name="origin_country"
                        label="Origin Country"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={originCountryFilter}
                            placeholder="Origin country"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="pickup_city"
                        label="PIckup City"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={pickupCityFilter}
                            placeholder="Pickup City"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="origin_station"
                        label="Origin Station"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={originStationFilter}
                            placeholder="Origin Airport Station"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="destination_station"
                        label="Destination Station"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={destinationStationFilter}
                            placeholder="Destination Station (airport code)"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="destination_country"
                        label="Destination Country"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={destinationCountryFilter}
                            placeholder="Destination Country"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="commodity"
                        label="Commodity"
                        rules={[{ required: true }]}
                    >
                        <AutoComplete
                            options={commoditiesFilter}
                            placeholder="Commodity"
                            filterOption={(inputValue, option) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="freight_forwarder"
                        label="Freight Forwarder"
                        rules={[{ required: false }]}
                    >
                        {/* <Input placeholder="Origin region" /> */}
                        <AutoComplete
                            placeholder="Freight Forwarder"
                            onChange={() =>
                                console.log(
                                    addLaneForm.getFieldValue(
                                        "freight_forwarder"
                                    )
                                )
                            }
                            filterOption={(
                                inputValue,
                                option
                            ) =>
                                option.value
                                    .toUpperCase()
                                    .indexOf(
                                        inputValue.toUpperCase()
                                    ) !== -1
                            }
                            options={freightForwarderFilter}
                        />
                    </Form.Item>

                    <Form.Item
                        {...{
                            wrapperCol: {
                                offset: 8,
                                span: 16,
                            },
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            // className="flex-row align-center"
                            disabled={newLaneAddLoading}
                        >
                            Submit
                            {newLaneAddLoading && <LoadingOutlined />}
                        </Button>
                        <Button
                            htmlType="button"
                            className="ml-medium"
                            onClick={() => {
                                addLaneForm.resetFields()
                            }}
                        >
                            Reset
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="flex-row align-center " style={{ width: "65%" }}>
                <h5 className="mr-medium  gray-text">Lane details</h5>
                <div className="flex-row align-center">
                    <Upload
                        {...props}
                        multiple
                        onChange={({ fileList }) => setFileList(fileList)}
                    >
                        <IconButton
                            Icon={UploadOutlined}
                            text="Select File(s)"
                        />
                    </Upload>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleUpload(fileList)
                        }}
                        style={{
                            display: fileList.length === 0 ? "none" : "block",
                        }}
                        disabled={fileList.length === 0}
                    // loading={uploading}
                    >
                        {uploading ? "Uploading" : "Upload"}
                    </Button>
                    <IconButton
                        Icon={PlusOutlined}
                        text="Add Lane"
                        onClick={() => {
                            setModalOpen(true)
                        }}
                    />
                    <IconButton
                        Icon={ExportOutlined}
                        text="Export CSV"
                        onClick={() => {
                            exportAirData()
                        }}
                    />
                    {exportData && exportData.length > 0 && (
                        <CSVLink
                            data={exportData}
                            className="air-export-csv-comp"
                            filename="E&I Global Air Forecast.csv"
                            ref={csvRef}
                        />
                    )}
                    <DatePicker
                        className="ml-medium"
                        placeholder="Due Date"
                        allowClear={false}
                        style={{
                            zoom: "125%",
                            height: 26,
                            width: 120,
                        }}
                        onChange={(date, dateString) => {
                            // console.log(dateString)
                            updateDueDate(dateString)
                        }}
                    // popupClassName="road-calendar"
                    />
                    <IconButton
                        Icon={DeleteOutlined}
                        text="Clear Filters"
                        onClick={() => {
                            console.log(filterRef)
                            filterRef.reset(duplicateData, true)
                            removeAirColumnFilterCookies()
                            dispatch(updateAirData(duplicateData))
                        }}
                    />
                    {/* <IconButton
                        Icon={SyncOutlined}
                        text={data === null ? "Syncing" : "Sync"}
                        spin={data === null ? true : false}
                        onClick={sync}
                    /> */}
                </div>
            </div>
            <div
                className="flex-row align-center space-between"
                style={{ width: 320 }}
            >
                <h4 className="gray-text">Forecast</h4>
                <IconButton
                    Icon={CheckOutlined}
                    text="Submit"
                    id="road-forecast-submit-btn"
                    className="road-forecast-submit-btn"
                    onClick={() => submitNewForecast()}
                />
            </div>
        </div>
    )
}
export default AirTableHeader
