export const europeRoadColumns = [
    {
        title: "Row ID",
        dataIndex: "row_id",
        key: "row_id",
        filterKey: "row_id",
        visibility: true,
    },
    {
        title: "Lane ID",
        dataIndex: "lane_id",
        key: "lane_id",
        filterKey: "lane_id",
        visibility: true,
    },
    {
        title: "Origin Country",
        dataIndex: "origin_country",
        key: "origin_country",
        filterKey: "origin_country",
        visibility: true,
    },
    {
        title: "New Shipping Group",
        dataIndex: "new_shipping_group",
        key: "new_shipping_group",
        filterKey: "new_shipping_group",
        visibility: true,
    },
    {
        title: "Origin normalized",
        dataIndex: "origin_normalized",
        key: "origin_normalized",
        filterKey: "origin_normalized",
        visibility: true,
    },
    {
        title: "Origin Norm Range",
        dataIndex: "origin_norm_range",
        key: "origin_norm_range",
        filterKey: "origin_norm_range",
        visibility: true,
    },
    {
        title: "Destination Country",
        dataIndex: "destination_country",
        key: "destination_country",
        filterKey: "destination_country",
        visibility: true,
    },
    {
        title: "Destination Norm Range",
        dataIndex: "destination_norm_range",
        key: "destination_norm_range",
        filterKey: "destination_norm_range",
        visibility: true,
    },
    {
        title: "SBU",
        dataIndex: "sbu",
        key: "sbu",
        filterKey: "sbu",
        visibility: true,
    },
    {
        title: "Sub SBU",
        dataIndex: "sub_sbu",
        key: "sub_sbu",
        filterKey: "sub_sbu",
        visibility: true,
    },
    {
        title: "Data System",
        dataIndex: "data_system",
        key: "data_system",
        filterKey: "data_system",
        visibility: true,
    },
    {
        title: "FTL LTL Norm",
        dataIndex: "ftl_ltl_norm",
        key: "ftl_ltl_norm",
        filterKey: "ftl_ltl_norm",
        visibility: true,
    },
    {
        title: "Shipment Number",
        dataIndex: "shipment_number",
        key: "shipment_number",
        filterKey: "shipment_number",
        visibility: true,
    },
    {
        title: "LTL Count",
        dataIndex: "ltl_count",
        key: "ltl_count",
        filterKey: "ltl_count",
        visibility: true,
    },
    {
        title: "FTL Count",
        dataIndex: "ftl_count",
        key: "ftl_count",
        filterKey: "ftl_count",
        visibility: true,
    },
    {
        title: "HAZ Count",
        dataIndex: "haz_count",
        key: "haz_count",
        filterKey: "haz_count",
        visibility: true,
    },
    {
        title: "Temp Count",
        dataIndex: "temp_count",
        key: "temp_count",
        filterKey: "temp_count",
        visibility: true,
    },
    {
        title: "Freeze Count",
        dataIndex: "freeze_count",
        key: "freeze_count",
        filterKey: "freeze_count",
        visibility: true,
    },
    // {
    //     title: "Awarded Suppliers",
    //     dataIndex: "awarded_suppliers",
    //     key: "awarded_suppliers",
    //     filterKey: "awarded_suppliers",
    //     visibility: true,
    // },
    // {
    //     title: "Month1 History",
    //     dataIndex: "month1_history",
    //     key: "month1_history",
    //     filterKey: "month1_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month2 History",
    //     dataIndex: "month2_history",
    //     key: "month2_history",
    //     filterKey: "month2_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month3 History",
    //     dataIndex: "month3_history",
    //     key: "month3_history",
    //     filterKey: "month3_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month4 History",
    //     dataIndex: "month4_history",
    //     key: "month4_history",
    //     filterKey: "month4_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month5 History",
    //     dataIndex: "month5_history",
    //     key: "month5_history",
    //     filterKey: "month5_history",
    //     visibility: true,
    // },
    // {
    //     title: "Month6 History",
    //     dataIndex: "month6_history",
    //     key: "month6_history",
    //     filterKey: "month6_history",
    //     visibility: true,
    // },
    // {
    //     title: "Six Months History Total",
    //     dataIndex: "six_months_history_total",
    //     key: "six_months_history_total",
    //     filterKey: "six_months_history_total",
    //     visibility: true,
    // },
    {
        title: "Annual Forecast",
        dataIndex: "annual_forecast",
        key: "annual_forecast",
        filterKey: "annual_forecast",
        visibility: true,
    },
    {
        title: "Month1 Forecast",
        dataIndex: "month1_forecast",
        key: "month1_forecast",
        filterKey: "month1_forecast",
        visibility: true,
    },
    {
        title: "Month2 Forecast",
        dataIndex: "month2_forecast",
        key: "month2_forecast",
        filterKey: "month2_forecast",
        visibility: true,
    },
    {
        title: "Month3 Forecast",
        dataIndex: "month3_forecast",
        key: "month3_forecast",
        filterKey: "month3_forecast",
        visibility: true,
    },
    {
        title: "Month4 Forecast",
        dataIndex: "month4_forecast",
        key: "month4_forecast",
        filterKey: "month4_forecast",
        visibility: true,
    },
    {
        title: "Month5 Forecast",
        dataIndex: "month5_forecast",
        key: "month5_forecast",
        filterKey: "month5_forecast",
        visibility: true,
    },
    {
        title: "Month6 Forecast",
        dataIndex: "month6_forecast",
        key: "month6_forecast",
        filterKey: "month6_forecast",
        visibility: true,
    },
    {
        title: "Month7 Forecast",
        dataIndex: "month7_forecast",
        key: "month7_forecast",
        filterKey: "month7_forecast",
        visibility: true,
    },
    {
        title: "Month8 Forecast",
        dataIndex: "month8_forecast",
        key: "month8_forecast",
        filterKey: "month8_forecast",
        visibility: true,
    },
    {
        title: "Month9 Forecast",
        dataIndex: "month9_forecast",
        key: "month9_forecast",
        filterKey: "month9_forecast",
        visibility: true,
    },
    {
        title: "Month10 Forecast",
        dataIndex: "month10_forecast",
        key: "month10_forecast",
        filterKey: "month10_forecast",
        visibility: true,
    },
    {
        title: "Month11 Forecast",
        dataIndex: "month11_forecast",
        key: "month11_forecast",
        filterKey: "month11_forecast",
        visibility: true,
    },
    {
        title: "Month12 Forecast",
        dataIndex: "month12_forecast",
        key: "month12_forecast",
        filterKey: "month12_forecast",
        visibility: true,
    },
    {
        title: "Last Updated Datetime",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
        filterKey: "last_updated_datetime",
        visibility: true,
    },
    {
        title: "User Updated",
        dataIndex: "user_updated",
        key: "user_updated",
        filterKey: "user_updated",
        visibility: true,
    },
    // {
    //     title: "First Year",
    //     dataIndex: "first_year",
    //     key: "first_year",
    //     filterKey: "first_year",
    //     visibility: true,
    // },
    // {
    //     title: "First Month",
    //     dataIndex: "first_month",
    //     key: "first_month",
    //     filterKey: "first_month",
    //     visibility: true,
    // },
    {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        filterKey: "due_date",
        visibility: true,
    },
    {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        filterKey: "comments",
        visibility: true,
    },
]

export const roadForecastcolumn = [
    {
        key: "forecast column",
        title: "Current forecast",
        dataIndex: "cur_forecast",
        filterKey: "cur_forecast",
        visibility: true,
        Children: <h1>a</h1>,
        style: {
            width: 300,
        },
    },
]
