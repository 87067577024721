import React from "react"
import Cookies from "universal-cookie"
const Test = () => {
    const cookies = new Cookies()

    return (
        <div>
            <button
                onClick={() => {
                    cookies.set("name", "rakesh")
                }}
            >
                setCookie
            </button>
            <button
                onClick={() => {
                    console.log(cookies.getAll())
                }}
            >
                getCookie
            </button>
            <button
                onClick={() => {
                    // cookies.remove("filters")
                    // cookies.remove("oceanFilters")
                    // cookies.remove("testFilters")
                }}
            >
                Remove cookies
            </button>
        </div>
    )
}

export default Test
