import React, { useContext, useEffect, useState } from "react"
import TableFilter from "react-table-filter"
import "react-table-filter/lib/styles.css"
import { setScrollListner } from "../Utils/scrollListner"
import CustomTableRow from "./CustomTableRow"
// import { ForecastTableHeader } from "./CustomDatePicker"
// import CommentField from "./CommentField"
// import AddComment from "./AddComment"
import { Form, Modal, message } from "antd"
import CommentField from "./CommentField"
import { useDispatch } from "react-redux"
import {
    getOceanForecastData,
    updateOceanData,
} from "../../Redux/actions/oceanActions"
import ForecastTableHeader from "./ForecastTableHeader"
import { oceanContext } from ".."
import axios from "axios"
import CommentModal from "./CommentModal"
import {
    getOceanColumnFilterCookies,
    updateOceanColumnFilterCookies,
} from "../Utils/updateCookies"
const { confirm } = Modal

const CustomTable = ({
    entireData = [],
    trimmedData = [],
    columns,
    forecastColumn = null,
    page,
}) => {
    let visibility =
        forecastColumn && columns && columns[columns.length - 1].visibility

    const {
        setCurrentForecastMonth,
        currentForecastMonth,
        filterRef,
        setFilterRef,
    } = useContext(oceanContext)
    const [commentModalOpen, setCommentModalOpen] = useState(false)
    const [commentingRow, setCommentingRow] = useState("")
    const [commentAddLoading, setCommentAddLoading] = useState(false)
    const [commentForm] = Form.useForm()
    const dispatch = useDispatch()
    const { config } = useContext(oceanContext)

    useEffect(() => {
        setScrollListner()
    }, [trimmedData, entireData, columns, visibility])

    const handleCommentSubmit = async () => {
        setCommentAddLoading(true)
        await axios
            .post(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/comment`,
                {
                    ocean_lane_id: commentingRow,
                    ...commentForm.getFieldsValue(),
                },
                config
            )
            .then((res) => {
                setCommentModalOpen(false)
                message.success(res.data)
                dispatch(getOceanForecastData(config))
                message.loading("Refreshing comments")
                setCommentAddLoading(false)
                commentForm.resetFields()
            })
            .catch((err) => {
                console.log(err)
                setCommentAddLoading(false)
                commentForm.resetFields()
            })
    }

    const deleteComment = async () => {
        confirm({
            title: "This will delete previous comments for this lane. Are you sure you want to proceed?",
            okText: "Yes, Proceed",
            cancelText: "No",
            async onOk() {
                message.loading("Deleting comments, please wait... ", 10)
                await axios
                    .post(
                        `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/clearcomment`,
                        {
                            ocean_lane_id: commentingRow,
                        },
                        config
                    )
                    .then((res) => {
                        message.destroy()
                        setCommentModalOpen(false)
                        message.success(res.data)
                        dispatch(getOceanForecastData(config))
                        message.loading("Refreshing comments")
                        commentForm.resetFields()
                    })
                    .catch((err) => {
                        console.log(err)
                        commentForm.resetFields()
                    })
            },
        })
    }

    return (
        <div className="road-table-container flex-row">
            <CommentModal
                setIsOpen={setCommentModalOpen}
                isOpen={commentModalOpen}
                row={commentingRow}
                commentForm={commentForm}
                handleCommentSubmit={handleCommentSubmit}
                commentAddLoading={commentAddLoading}
                deleteComment={deleteComment}
            />
            {entireData ? (
                <div className="first-table-container">
                    <table border={1} className="first-table">
                        <thead>
                            <TableFilter
                                className="filter-comp"
                                rows={entireData}
                                rowClass={"table-header"}
                                initialFilters={getOceanColumnFilterCookies()}
                                onFilterUpdate={(
                                    newData,
                                    filterConfiguration
                                ) => {
                                    dispatch(updateOceanData(newData))
                                    updateOceanColumnFilterCookies(
                                        filterConfiguration
                                    )
                                }}
                                ref={(node) => {
                                    setFilterRef(node)
                                }}
                            >
                                {columns &&
                                    columns
                                        .filter(
                                            (column) =>
                                                column.visibility &&
                                                column.dataIndex !==
                                                    "cur_forecast"
                                        )
                                        .map((column) => (
                                            <th
                                                key={column.DataIndex}
                                                className={`table-header col-${column.dataIndex}`}
                                                filterkey={column.dataIndex}
                                                style={column.style || {}}
                                            >
                                                {column.title}
                                            </th>
                                        ))}
                            </TableFilter>
                        </thead>
                        <tbody>
                            {trimmedData && trimmedData.length
                                ? trimmedData.map((row, index) => (
                                      <tr
                                          key={row.ocean_lane_id}
                                          className={`ftr-${index}`}
                                      >
                                          {columns.map((col) => {
                                              if (
                                                  col.visibility &&
                                                  col.dataIndex === "comment"
                                              ) {
                                                  return (
                                                      <CommentField
                                                          key={`comment-${row.ocean_lane_id}`}
                                                          row={row}
                                                          setCommentModalOpen={
                                                              setCommentModalOpen
                                                          }
                                                          commentModalOpen={
                                                              commentModalOpen
                                                          }
                                                          setCommentingRow={
                                                              setCommentingRow
                                                          }
                                                          index={index}
                                                          commentValue={
                                                              row[`comment`]
                                                          }
                                                      />
                                                  )
                                              } else if (
                                                  col.visibility &&
                                                  col.dataIndex !==
                                                      "cur_forecast"
                                              ) {
                                                  return (
                                                      <td
                                                          className="first"
                                                          key={`${col.dataIndex}-${row.ocean_lane_id}`}
                                                          style={
                                                              col.style || {}
                                                          }
                                                      >
                                                          {row[col.dataIndex]}
                                                      </td>
                                                  )
                                              } else return <></>
                                          })}
                                      </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            ) : null}
            {forecastColumn !== null &&
                columns &&
                columns.length > 0 &&
                columns[columns.length - 1].visibility && (
                    <div className="second-table-container">
                        <table border={1} className="forecast-table">
                            <thead>
                                <tr>
                                    <th style={forecastColumn[0].style || {}}>
                                        <ForecastTableHeader />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {trimmedData && trimmedData.length
                                    ? trimmedData.map((row, index) => (
                                          <tr
                                              className="bw "
                                              key={row.ocean_lane_id}
                                          >
                                              <td>
                                                  <CustomTableRow
                                                      index={index}
                                                      row={row}
                                                      currentForecast={
                                                          row[
                                                              `${"asdf"}_forecast`
                                                          ] || "0"
                                                      }
                                                      disabled={
                                                          currentForecastMonth ===
                                                          ""
                                                      }
                                                  />
                                              </td>
                                          </tr>
                                      ))
                                    : null}
                            </tbody>
                        </table>
                    </div>
                )}
        </div>
    )
}
export default CustomTable
