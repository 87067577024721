import React from "react"
import { NavigationBar } from "./Components/NavigationBar"
import { StickyNavigation } from "./Components/stickyNavigatebar"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Pages/Home"
import FAQ from "./Pages/Faq"
import Test from "./Pages/Test"
import Road from "./Road"
import Ocean from "./Ocean"
// import DataProvider from "./Road/Redux/DataProvider"
import ProtectedRoute from "./Pages/Login"
import NotFound from "./Pages/NotFound"
import Air from "./Air"
import { DataProvider } from "./Redux"

function App() {
    return (
        <DataProvider>
            <BrowserRouter>
                <NavigationBar />
                <StickyNavigation />
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ProtectedRoute>
                                <Home />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Ocean"
                        element={
                            <ProtectedRoute>
                                <Ocean />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/Air"
                        element={
                            <ProtectedRoute>
                                <Air />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/na_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/europe_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/korea_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/japan_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/china_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/taiwan_road"
                        element={
                            <ProtectedRoute>
                                <Road />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/needHelp" element={<FAQ />} />
                    <Route path="/test" element={<Test />} />
                    <Route
                        path="/*"
                        default
                        element={
                            <ProtectedRoute>
                                <NotFound />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </DataProvider>
    )
}

export default App
