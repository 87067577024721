import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate} from "react-router-dom";
import TextButton from "./Button";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();
  let history = useNavigate();
  const handleLogout = () => {
    history("/");
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  return (
    <>
      <TextButton handler={handleLogout} text="Sign out" type={"default"} styles={{ marginRight: "20px" }}/>
    </>
  );
};
