export const AirColumns = [
    {
        title: "Sub Business Unit",
        dataIndex: "business_unit",
        key: "business_unit",
        filterKey: "business_unit",
        visibility: true,
    },
    {
        title: "Business Segment",
        dataIndex: "business_segment",
        key: "business_segment",
        filterkey: "business_segment",
        visibility: true,

    },
    
    {
        title: "Origin Region",
        dataIndex: "origin_region",
        key: "origin_region",
        filterKey: "origin_region",
        visibility: true,
    },
    {
        title: "Origin Country",
        dataIndex: "origin_country",
        key: "origin_country",
        filterKey: "origin_country",
        visibility: true,
    },
    {
        title: "Pickup City",
        dataIndex: "pickup_city",
        key: "pickup_city",
        filterKey: "pickup_city",
        visibility: true,
    },
    {
        title: "Origin Station",
        dataIndex: "origin_station",
        key: "origin_station",
        filterKey: "origin_station",
        visibility: true,
    },
    {
        title: "Origin Airport",
        dataIndex: "origin_airport",
        key: "origin_airport",
        filterKey: "origin_airport",
        visibility: true,
    },
    {
        title: "Origin Airport location",
        dataIndex: "origin_airport_location",
        key: "origin_airport_location",
        filterKey: "origin_airport_location",
        visibility: true,
    },
    {
        title: "Destination Region",
        dataIndex: "destination_region",
        key: "destination_region",
        filterKey: "destination_region",
        visibility: true,
    },
    {
        title: "Destination Country",
        dataIndex: "destination_country",
        key: "destination_country",
        filterKey: "destination_country",
        visibility: true,
    },

    {
        title: "Destination Airport Location",
        dataIndex: "destination_airport_location",
        key: "destination_airport_location",
        filterKey: "destination_airport_location",
        visibility: true,
    },
    {
        title: "Destination Station",
        dataIndex: "destination_station",
        key: "destination_station",
        filterKey: "destination_station",
        visibility: true,
    },
    {
        title: "Destination Airport",
        dataIndex: "destination_airport",
        key: "destination_airport",
        filterKey: "destination_airport",
        visibility: true,
    },
    {
        title: "Freight Forwarder",
        dataIndex: "freight_forwarder",
        key: "freight_forwarder",
        filterKey: "freight_forwarder",
        visibility: true,
    },
    {
        title: "Commodity",
        dataIndex: "commodity",
        key: "commodity",
        filterKey: "commodity",
        visibility: true,
    },
    {
        title: "Demand differential",
        dataIndex: "demand_differential",
        key: "demand_differential",
        filterKey: "demand_differential",
        visibility: true,
    },
    {
        title: "Forwarder Input",
        dataIndex: "forwarder_input",
        key: "forwarder_input",
        filterKey: "forwarder_input",
        visibility: true,
    },
    {
        title: "month1history",
        dataIndex: "month1_history",
        key: "month1_history",
        filterKey: "month1_history",
        visibility: true,
    },
    {
        title: "month2history",
        dataIndex: "month2_history",
        key: "month2_history",
        filterKey: "month2_history",
        visibility: true,
    },
    {
        title: "month3history",
        dataIndex: "month3_history",
        key: "month3_history",
        filterKey: "month3_history",
        visibility: true,
    },
    {
        title: "month4history",
        dataIndex: "month4_history",
        key: "month4_history",
        filterKey: "month4_history",
        visibility: true,
    },
    {
        title: "month5history",
        dataIndex: "month5_history",
        key: "month5_history",
        filterKey: "month5_history",
        visibility: true,
    },
    {
        title: "month6history",
        dataIndex: "month6_history",
        key: "month6_history",
        filterKey: "month6_history",
        visibility: true,
    },
    {
        title: "Six Months History Total",
        dataIndex: "six_months_history_total",
        key: "six_months_history_total",
        filterKey: "six_months_history_total",
        visibility: true,
    },
    {
        title: "Six Months History Avg",
        dataIndex: "six_months_history_avg",
        key: "six_months_history_avg",
        filterKey: "six_months_history_avg",
        visibility: true,
    },
    {
        title: "month1forecast",
        dataIndex: "month1_forecast",
        key: "month1_forecast",
        filterKey: "month1_forecast",
        visibility: true,
    },
    {
        title: "month2forecast",
        dataIndex: "month2_forecast",
        key: "month2_forecast",
        filterKey: "month2_forecast",
        visibility: true,
    },
    {
        title: "month3forecast",
        dataIndex: "month3_forecast",
        key: "month3_forecast",
        filterKey: "month3_forecast",
        visibility: true,
    },
    {
        title: "month4forecast",
        dataIndex: "month4_forecast",
        key: "month4_forecast",
        filterKey: "month4_forecast",
        visibility: true,
    },
    {
        title: "month5forecast",
        dataIndex: "month5_forecast",
        key: "month5_forecast",
        filterKey: "month5_forecast",
        visibility: true,
    },
    {
        title: "month6forecast",
        dataIndex: "month6_forecast",
        key: "month6_forecast",
        filterKey: "month6_forecast",
        visibility: true,
    },
    {
        title: "month7forecast",
        dataIndex: "month7_forecast",
        key: "month7_forecast",
        filterKey: "month7_forecast",
        visibility: true,
    },
    {
        title: "month8forecast",
        dataIndex: "month8_forecast",
        key: "month8_forecast",
        filterKey: "month8_forecast",
        visibility: true,
    },
    {
        title: "month9forecast",
        dataIndex: "month9_forecast",
        key: "month9_forecast",
        filterKey: "month9_forecast",
        visibility: true,
    },
    {
        title: "month10forecast",
        dataIndex: "month10_forecast",
        key: "month10_forecast",
        filterKey: "month10_forecast",
        visibility: true,
    },
    {
        title: "month11forecast",
        dataIndex: "month11_forecast",
        key: "month11_forecast",
        filterKey: "month11_forecast",
        visibility: true,
    },
    {
        title: "month12forecast",
        dataIndex: "month12_forecast",
        key: "month12_forecast",
        filterKey: "month12_forecast",
        visibility: true,
    },
    {
        title: "Yearly Forecast",
        visibility: true,
        filterkey: "year_forecast",
        dataIndex: "year_forecast",
        key: "year_forecast",
    },
    {
        title: "User updated",
        visibility: true,
        filterkey: "user_updated",
        dataIndex: "user_updated",
        key: "user_updated",
    },

    {
        title: "Last Updated Time",
        visibility: true,
        filterkey: "last_updated_datetime",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
    },
    {
        title: "Comments",
        visibility: true,
        filterkey: "dupont_input",
        dataIndex: "dupont_input",
        key: "dupont_input",
    },
    {
        title: "Due Date",
        visibility: true,
        filterkey: "due_date",
        dataIndex: "due_date",
        key: "due_date",
    },
]
export const airForecastcolumn = [
    {
        key: "forecast column",
        title: "Current forecast",
        dataIndex: "cur_forecast",
        filterKey: "cur_forecast",
        visibility: true,
        Children: <h1>a</h1>,
        style: {
            width: 300,
        },
    },
]
