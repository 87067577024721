import React from "react"
import { MessageOutlined } from "@ant-design/icons"
import { Tooltip } from "antd"

//A Table field to display comments and add comments from the table

function CommentField({
    commentModalOpen,
    commentValue,
    setCommentingRow,
    setCommentModalOpen,
    row,
    index,
}) {
    return (
        <div className="comment-field">
            <span>{commentValue || "NA"}</span>
            <div style={{ zoom: "125%", fontSize: "0.5em" }}>
                <Tooltip title="Comment">
                    <MessageOutlined
                        className="ml-small add-comment"
                        style={{
                            fontSize: "15px",
                        }}
                        onClick={() => {
                            setCommentModalOpen(true)
                            setCommentingRow(row.ocean_lane_id)
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    )
}

export default CommentField
