import { useEffect, useState, createContext, useRef } from "react"
import "./Styles/index.css"
import { useMsal } from "@azure/msal-react"
import axios from "axios"
import { getAccessToken } from "../Utils/helperFunctions"
import PageBody from "./Components/PageBody"
import { naRoadColumns, roadForecastcolumn } from "./Utils/naColumns"
import RoadTableHeader from "./Components/TableHeader"
import {
    getRoadColumnCookies,
    getRoadFirstMonth,
    updateRoadColumnCookies,
    updateRoadFirstMonth,
} from "./Utils/updateCookies"
import { useDispatch, useSelector } from "react-redux"
import {
    getRoadForecastData,
    updateDuplicateRoadData,
    updateRoadColumns,
    updateRoadData,
} from "../Redux/actions/roadActions"
import {
    getAppropriateMonthNames,
    getForecastColumnsList,
} from "./Utils/dynamicMonths"
import { month_dict } from "../Utils/tableutils"
import { message } from "antd"
import { useLocation } from "react-router-dom"
import { EUROPE_ROAD, KOREA_ROAD, NA_ROAD, JAPAN_ROAD, CHINA_ROAD, TAIWAN_ROAD } from "./Utils/regions"
import { koreaRoadColumns } from "./Utils/koreaColumns"
import { europeRoadColumns } from "./Utils/europeColumns"
import { japanRoadColumns } from "./Utils/japanColumns"
import { chinaRoadColumns } from "./Utils/chinaColumns"
import { taiwanRoadColumns } from "./Utils/taiwanColumns"
export const roadContext = createContext()

function Road() {
    const { instance, inProgress, accounts } = useMsal()
    // const { config } = useContext(AppContext)
    const [currentForecastMonth, setCurrentForecastMonth] = useState("")
    const [currentForecastMonthStore, setCurrentForecastMonthStore] =
        useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(50)
    const forecastPickerRef = useRef()
    const [config, setConfig] = useState(null)
    const [filterRef, setFilterRef] = useState(null)
    const [forecastMonthStore, setForecastMonthStore] = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    const [forecastColumns, setForecastColumns] = useState(null)
    const dispatch = useDispatch()
    const { data, columns } = useSelector((state) => state.road)
    const [savedColumnFilters, setSavedColumnFilters] = useState(null)
    const region = useLocation().pathname.substring(1)

    /**
        * Whenever there's a change in firstmonth value (response from API), 
                we are recalculating month names.
        * firstmonth value indicates the first month name in the data.
        * in data, we get month1_history, month2_history and so on.
        * but for user convenience, we are generating appropriate month names(using ) using this API response.
        * for ex, first_month: 6, first_year: 2022 then month1_history will be renamed as May 2022 History. 
        */
    const getFirstMonth = async (region) => {
        await axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/${region}/firstmonth`,
                config
            )
            .then(({ data }) => {
                let cached = getRoadFirstMonth(region)
                let dynamicMonths
                switch (region) {
                    case NA_ROAD:
                        dynamicMonths = getAppropriateMonthNames(
                            data.first_month,
                            data.first_year,
                            naRoadColumns
                        )
                        break
                    case KOREA_ROAD:
                        dynamicMonths = getAppropriateMonthNames(
                            data.first_month,
                            data.first_year,
                            koreaRoadColumns
                        )
                        break
                    case EUROPE_ROAD:
                            dynamicMonths = getAppropriateMonthNames(
                                data.first_month,
                                data.first_year,
                                europeRoadColumns
                            )
                            break
                    case JAPAN_ROAD:
                                dynamicMonths = getAppropriateMonthNames(
                                    data.first_month,
                                    data.first_year,
                                    japanRoadColumns
                                )
                                break
                    
                    case CHINA_ROAD:
                        dynamicMonths = getAppropriateMonthNames(
                            data.first_month,
                            data.first_year,
                            chinaRoadColumns
                        )
                        break
                    
                    case TAIWAN_ROAD:
                            dynamicMonths = getAppropriateMonthNames(
                                data.first_month,
                                data.first_year,
                                taiwanRoadColumns
                            )
                            break
                    default:
                        break
                }

                if (isNaN(cached.first_month)) {
                    updateRoadFirstMonth(data, region)
                    dispatch(updateRoadColumns(dynamicMonths))
                    updateRoadColumnCookies(
                        // getAppropriateMonthNames(
                        //     data.first_month,
                        //     data.first_year,
                        // ),
                        dynamicMonths,
                        region
                    )
                    // setForecastColumns(getForecastColumnsList(dynamicMonths))
                } else if (
                    cached.first_month !== data.first_month ||
                    cached.first_year !== data.first_year
                ) {
                    dispatch(updateRoadColumns(dynamicMonths))
                    updateRoadColumnCookies(dynamicMonths, region)
                    updateRoadFirstMonth(data, region)
                    // setForecastColumns(getForecastColumnsList(dynamicMonths))
                }
                // else {
                //     updateRoadFirstMonth(data, region)
                //     dispatch(updateRoadColumns(dynamicMonths))
                //     updateRoadColumnCookies(
                //         getAppropriateMonthNames(
                //             data.first_month,
                //             data.first_year
                //         ),
                //         region
                //     )
                // }
                setForecastColumns(getForecastColumnsList(dynamicMonths))
            })
            .catch((err) => err.message)
    }

    useEffect(() => {
        const getAccessTokenHandler = async () => {
            const accessToken = await getAccessToken(
                instance,
                inProgress,
                accounts
            )
            if (accessToken) {
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken.access}`,
                        "Content-Type": "application/json",
                        id: `${accessToken.id}`,
                    },
                }
                setConfig(config)
            }
        }
        getAccessTokenHandler()
    }, [instance, accounts, inProgress])

    useEffect(() => {
        const initializer = async () => {
            dispatch(updateRoadData(null))
            if (config) {
                await getRoadData(region).then(() => {
                    getFirstMonth(region)
                    let roadColumnsCookies = getRoadColumnCookies(region)
                    if (roadColumnsCookies) {
                        dispatch(updateRoadColumns(roadColumnsCookies))
                    } else {
                        switch (region) {
                            case NA_ROAD:
                                dispatch(
                                    updateRoadColumns([
                                        ...naRoadColumns,
                                        ...roadForecastcolumn,
                                    ])
                                )
                                break
                            case KOREA_ROAD:
                                dispatch(
                                    updateRoadColumns([
                                        ...koreaRoadColumns,
                                        ...roadForecastcolumn,
                                    ])
                                )
                                break
                            case EUROPE_ROAD:
                                dispatch(
                                    updateRoadColumns([
                                        ...europeRoadColumns,
                                        ...roadForecastcolumn,
                                    ])
                                )
                                break
                            case JAPAN_ROAD:
                                    dispatch(
                                        updateRoadColumns([
                                            ...japanRoadColumns,
                                            ...roadForecastcolumn,
                                        ])
                                    )
                                    break
                            default:
                                break
                        }
                    }
                })
            }
        }
        initializer()
    }, [config, region])

    const getRoadData = async (region) => {
        axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/${region}/forecast`,
                config
            )
            .then(({ data }) => {
                dispatch(updateRoadData(data))
                dispatch(updateDuplicateRoadData(data))
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        const inputs = document.querySelectorAll(".forecast-input")
        inputs.forEach((input) => {
            input.value = ""
        })

        const forecastSpans = document.querySelectorAll(".cur-forecast-disp")
        forecastSpans.forEach((item) => {
            item.innerHTML = "0"
        })

        if (currentForecastMonth !== "") {
            // let month = parseInt(currentForecastMonth.slice(5, 7))
            // let year = parseInt(currentForecastMonth.slice(0, 4))
            let column = columns.filter((column) => {
                return column.dataIndex === currentForecastMonth
            })
            // if (
            //     column.length === 0 ||
            //     column === undefined ||
            //     !column[0].dataIndex
            // ) {
            //     message.error("Please choose a valid month")
            //     return
            // }
            const forecastSpans =
                document.querySelectorAll(".cur-forecast-disp")
            forecastSpans.forEach((item) => {
                const numberRegex = /\d+/g
                const index = parseInt(item.classList[1].match(numberRegex)[0])
                item.innerHTML = data[index][column[0].dataIndex]
                    ? Math.round(data[index][column[0].dataIndex] * 1000) / 1000
                    : "0"
            })
            const forecastSubmitBtn = document.getElementById(
                "road-forecast-submit-btn"
            )
            inputs.forEach((input) => {
                input.value = ""
                input.addEventListener("keyup", (e) => {
                    if (e.keyCode === 13) {
                        forecastSubmitBtn.click()
                    }
                })
            })
        }
    }, [currentForecastMonth, currentPage, data])

    const submitNewForecast = async () => {
        console.log({ currentForecastMonth })
        if (currentForecastMonth === "") {
            message.warning("Please choose a month :)")
            return
        }
        const inputs = document.querySelectorAll(".forecast-input")
        const numRegEx = /\d+/g
        let posts = []
        
        let column = columns.filter(
            (column) => column.dataIndex === currentForecastMonth
        )[0]
        inputs.forEach((input) => {
            let lane_id = parseInt(input.classList[1].match(numRegEx))
            let post = {}
            if (input.value !== "") {
                post[`${region}_lane_id`] = lane_id
                post["shipments"] = parseInt(input.value)
                post["forecast_month"] = parseInt(
                    column.dataIndex.match(numRegEx)[0]
                )
                posts.push(post)
            }
        })
        if (posts.length === 0) {
            message.warning("please enter valid forecast values")
            return
        }
        messageApi.open({
            key: "updatingForecast",
            type: "loading",
            content: "submitting new forecasts...",
            duration: 15,
        })
        const forecastSubmitBtn = document.getElementById(
            "road-forecast-submit-btn"
        )
        forecastSubmitBtn.disabled = true
        setTimeout(() => {
            forecastSubmitBtn.disabled = false
        }, 5000)
        await axios
            .post(
                `${process.env.REACT_APP_FAST_API_APP_URL}/${region}/forecast`,
                { posts },
                config
            )
            .then((res) => {
                messageApi.open({
                    key: "updatingForecast",
                    type: "success",
                    content: res.data,
                })
                messageApi.open({
                    type: "loading",
                    content: "refreshing data...",
                })
                dispatch(getRoadForecastData(region, config))
            })
            .catch((err) => {
                console.log({ err })
                messageApi.open({
                    key: "updatingForecast",
                    type: "error",
                    content: err.message,
                })
            })
    }

    return (
        <div className="road-global-container">
            {contextHolder}
            <roadContext.Provider
                value={{
                    setCurrentForecastMonth,
                    currentForecastMonth,
                    page: "road",
                    currentPage,
                    setCurrentPage,
                    pageSize,
                    setPageSize,
                    config,
                    filterRef,
                    setFilterRef,
                    forecastPickerRef,
                    savedColumnFilters,
                    setSavedColumnFilters,
                    currentForecastMonthStore,
                    setCurrentForecastMonthStore,
                    forecastColumns,
                    forecastMonthStore,
                    setForecastMonthStore,
                    region,
                }}
            >
                <PageBody
                    tableHeader={
                        <RoadTableHeader
                            submitNewForecast={submitNewForecast}
                            // handleUpload={handleUpload}
                            region={region}
                        />
                    }
                    page="road"
                    reportType={"Monthly"}
                    columns={columns}
                    data={data}
                    forecastColumn={roadForecastcolumn}
                />
            </roadContext.Provider>
        </div>
    )
}

export default Road
