import Cookies from "universal-cookie"

//setting columns to the localStorage as cookies, to store column view
export const updateAirColumnCookies = (columns) => {
    localStorage.setItem("EI_Air_Columns", JSON.stringify(columns))
}
// returning column cookies from the localStorage
export const getAirColumnCookies = () => {
    return JSON.parse(localStorage.getItem("EI_Air_Columns"))
}

const cookies = new Cookies()
export const updateAirFirstMonth = (data) => {
    cookies.set("AirFirstMonth", data.first_month)
    cookies.set("AirFirstYear", data.first_year)
}
export const getAirFirstMonth = () => {
    return {
        first_month: parseInt(cookies.get("AirFirstMonth")),
        first_year: parseInt(cookies.get("AirFirstYear")),
    }
}
export const updateAirColumnFilterCookies = (filters) => {
    localStorage.setItem("AirColumnFilters", JSON.stringify(filters))
}

export const getAirColumnFilterCookies = () => {
    return JSON.parse(localStorage.getItem("AirColumnFilters"))
}
export const removeAirColumnFilterCookies = () => {
    localStorage.removeItem("AirColumnFilters")
}
