import { naRoadColumns, roadForecastcolumn } from "./naColumns"
import { month_dict } from "../../Utils/tableutils"

/**
 * @param {*} firstMonth : first month is a response from the server. that indicates, first month name in the data.
 *              for ex, firstmonth: 5, then it all starts from May <firstYear> history and so on.
 * @param {*} firstYear resposne from the server indicating that the first columns year.
 * @returns an array of columns for the road, by renaming columns.
 *              for ex, firstMonth : 5, firstYear :2022 then, month1_history will be renamed as May 2022 History.
 */

export const getAppropriateMonthNames = (firstMonth = 1, firstYear = 2022, roadColumns = naRoadColumns) => {
    let columns = [...roadColumns, ...roadForecastcolumn]
    columns = columns.map((column) => {
        // using regex to find the columns with month1_forecast to month12_forecast and month1_history to month6_history
        const patternForecast = /month[0-9]*_forecast/
        const patternHistory = /month[0-9]*_history/
        const numberRegex = /\d+/g
        let { title } = column
        if (patternHistory.test(column.dataIndex)) {
            let monthIndex =
                (firstMonth + parseInt(title.match(numberRegex)) - 1) % 12
            if (monthIndex === 0) {
                monthIndex = 12
            }
            title = `${month_dict[monthIndex]} ${firstYear} History`
            firstYear = monthIndex === 12 ? firstYear + 1 : firstYear
        } else if (patternForecast.test(column.dataIndex)) {
            // first 6 months will be history and forecast coninues.
            let monthIndex =
                (firstMonth + 6 + parseInt(title.match(numberRegex)) - 1) % 12
            if (monthIndex === 0) {
                monthIndex = 12
            }
            title = `${month_dict[monthIndex]} ${firstYear} Forecast`
            firstYear = monthIndex === 12 ? firstYear + 1 : firstYear
        }
        if (
            patternForecast.test(column.dataIndex) ||
            patternHistory.test(column.dataIndex)
        ) {
            if (firstMonth > 12) {
                firstMonth = 1
                firstYear++
            }
        }
        return { ...column, title }
    })
    return columns
}

export const getForecastColumnsList = (columns) => {
    return columns
        .filter((column) => column.title.includes("Forecast"))
        .map((column) => ({
            ...column,
            label: column.title,
            value: column.dataIndex,
        }))
}
