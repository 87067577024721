import { Spin } from "antd"
import React from "react"

function Loading() {
    return (
        <div className="bubbles">
            <Spin size="large" />
        </div>
    )
}

export default Loading
