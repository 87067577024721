import React from "react"
import { Button } from "antd"
import { useMsal } from "@azure/msal-react"

export const TextButton = ({ text, handler, styles, type }) => {
    const { instance } = useMsal()
    return (
        <Button
            type={type ? type : "text"}
            onClick={() => handler(instance)}
            size="large"
            style={styles}
        >
            {text}
        </Button>
    )
}
export const IconButton = ({
    Icon,
    text,
    onClick,
    spin = false,
    className,
    id,
}) => {
    return (
        <Button
            className={`flex-row align-center large-padding ml-medium ${className}`}
            id={id}
            icon={<Icon spin={spin} />}
            style={{}}
            onClick={onClick}
        >
            {text}
        </Button>
    )
}
export default TextButton
