import { DatePicker, Select } from "antd"
import React, { useContext } from "react"
import { oceanContext } from ".."

function ForecastTableHeader() {
    const {
        setCurrentForecastMonth,
        forecastColumns,
        currentForecastMonthStore,
        setCurrentForecastMonthStore,
    } = useContext(oceanContext)
    return (
        <div className="forecast-col-header flex-row">
            <h6>Current Forecast</h6>
            {forecastColumns && (
                <Select
                    defaultValue={currentForecastMonthStore || null}
                    placeholder="forecast month"
                    style={{ width: 120, zoom: "125%" }}
                    onChange={(value) => {
                        console.log(value)
                        setCurrentForecastMonth(value)
                        setCurrentForecastMonthStore(value)
                    }}
                    options={forecastColumns}
                />
            )}
        </div>
    )
}

export default ForecastTableHeader
