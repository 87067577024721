import React from "react"
import { Navbar } from "react-bootstrap"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import Logo from "../Images/E&I.png"
import { SignInButton } from "./SignInButton"
import { SignOutButton } from "./SignOutButton"
import { AuthenticatedTemplate } from "@azure/msal-react"
import IconText from "./IconText"
import { UserOutlined, MailOutlined } from "@ant-design/icons"
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */

export const NavigationBar = () => {
    const isAuthenticated = useIsAuthenticated()
    let { accounts } = useMsal()

    return (
        <>
            <Navbar
                expand="lg"
                className="space-between navigation-outer zoom-90"
            >
                <Navbar.Brand href="/">
                    <img
                        src={Logo}
                        width="413"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <div className="flex-row align-center navigation-right">
                    {/* <Nav.Link href="/needHelp" className="navtext"> Need help? </Nav.Link> */}
                    <AuthenticatedTemplate>
                        <React.Fragment
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <a
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                href="mailto:timothy.payne@dupont.com?subject= Feedback on EI-ILP "
                                className="text-decoration-none black-text"
                            >
                                Support &nbsp;
                                <MailOutlined />
                            </a>
                            {accounts.length > 0 && accounts[0].name ? (
                                <IconText
                                    Icon={UserOutlined}
                                    text={accounts[0].name}
                                />
                            ) : null}
                        </React.Fragment>
                    </AuthenticatedTemplate>
                    {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                    <img
                        src="DuPont_Logo.png"
                        width="120"
                        height="47"
                        className="d-inline-block align-top dupont"
                        alt="React Bootstrap logo"
                    />
                </div>
            </Navbar>
            <br />
        </>
    )
}
