import React from "react"
import { useIsAuthenticated } from "@azure/msal-react"
import { SignInButton } from "../Components/SignInButton"

export function Login() {
    return (
        <div className="width-100 flex-row justify-center align-center h-70v login-div login-div">
            <div className="flex-col">
                <h2 className="gray-text">You aren't authenticated.</h2>
                <span>Please Sign in</span>
                <SignInButton type="primary" style={{ width: "fit-content" }} />
            </div>
        </div>
    )
}
/*
    ProtectedRoute component returns the page that requested, iff user is authenticated.
    Otherwise, Login page will be returned.
*/
const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useIsAuthenticated()
    return isAuthenticated ? children : <Login />
}
export default ProtectedRoute
