import { Button, Form, Input, Modal, Space } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

import React, { useState } from "react"

function CommentModal({
    setIsOpen,
    isOpen,
    row,
    commentForm,
    handleCommentSubmit,
    deleteComment,
    commentAddLoading,
}) {
    const [commentDeleting, setCommentDeleting] = useState(false)
    return (
        <Modal
            title="Add a Comment"
            style={{}}
            centered
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            footer={[]}
        >
            <Form form={commentForm} onFinish={handleCommentSubmit}>
                <Form.Item
                    name="comment"
                    label="Comment"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="Comment" />
                </Form.Item>

                <Form.Item
                    {...{
                        wrapperCol: {
                            offset: 4,
                            span: 10,
                        },
                    }}
                >
                    <Space direction="horizontal">
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={commentAddLoading}
                        >
                            Post comment
                            {commentAddLoading && <LoadingOutlined />}
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={() => {
                                commentForm.resetFields()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            danger
                            disabled={commentDeleting}
                            onClick={() => {
                                deleteComment()
                            }}
                        >
                            Delete Comment
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CommentModal
