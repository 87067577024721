import {
    legacy_createStore as createStore,
    combineReducers,
    applyMiddleware,
} from "redux"
import airReducer from "./reducers/airReducer"
import roadReducer from "./reducers/roadReducer"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { Provider } from "react-redux"
import oceanReducer from "./reducers/oceanReducer"

const rootReducer = combineReducers({
    air: airReducer,
    road: roadReducer,
    ocean: oceanReducer,
})

export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
)

export const DataProvider = ({ children }) => (
    <Provider store={store}>{children}</Provider>
)
