import { DatePicker, Modal, Upload, message } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { IconButton } from "../../Components/Button"
import { Button } from "react-bootstrap"
import { CSVLink } from "react-csv"
import {
    UploadOutlined,
    PlusOutlined,
    ExportOutlined,
    CheckOutlined,
    DeleteOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { oceanContext } from ".."
import axios from "axios"
import {
    getOceanForecastData,
    updateOceanData,
} from "../../Redux/actions/oceanActions"
import Swal from "sweetalert2"
import { removeOceanColumnFilterCookies } from "../Utils/updateCookies"

const OceanTableHeader = ({ submitNewForecast, setModalOpen }) => {
    const [fileList, setFileList] = useState([])
    const [messageApi, contextHolder] = message.useMessage()

    const [uploading, setUploading] = useState(false)
    const [exportData, setExportData] = useState([])
    const csvRef = useRef()
    const { config, filterRef } = useContext(oceanContext)
    const { duplicateData } = useSelector((state) => state.ocean)

    const { confirm } = Modal
    const dispatch = useDispatch()

    const exportOceanData = async () => {
        await axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/export`,
                config
            )
            .then(({ data }) => {
                setExportData(data)
                setTimeout(() => {
                    csvRef.current.link.click()
                }, 100)
                setTimeout(() => {
                    message.success("file exported successfully!!")
                }, 200)
            })
            .catch((err) => console.log(err))
    }

    const updateDueDate = async (date) => {
        if (date === "") return
        confirm({
            title: "Are you sure you want to update Due date?",
            async onOk() {
                try {
                    await axios
                        .post(
                            `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/duedate`,
                            { due_date: date },
                            config
                        )
                        .then((response) => {
                            message.success(response.data)
                            dispatch(getOceanForecastData(config))
                            message.loading("Updating table data!!", 1.5)
                        })
                        .catch((err) => message.error(err.message))
                } catch (e) {
                    return console.log("Oops errors!")
                }
            },
            onCancel() {},
        })
    }
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file])
            return false
        },
        fileList,
    }
    const handleUpload = async (fileList) => {
        console.log(fileList)
        let formData = new FormData()
        fileList.forEach((file) => {
            formData.append("uploadFiles", file.originFileObj)
        })
        let messageKey = "UPLOAD_FILES"
        Swal.fire({
            icon: "info",
            title: `Uploading files, Please hang on...`,
            showConfirmButton: false,
            timer: 240000,
            timerProgressBar: true,
        })
        // messageApi.open({
        //     key: messageKey,
        //     type: "loading",
        //     content: "Uploading files, please hang on...",
        //     duration: 300,
        // })
        await axios
            .patch(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/forecast`,
                formData,
                config
            )
            .then((response) => {
                console.log(response)
                Swal.close()
                messageApi.open({
                    key: messageKey,
                    type: "success",
                    // content: "Files uploaded successfully!",
                    content: response.data,
                    duration: 5,
                })
                setFileList([])
            })
            .catch((err) => {
                Swal.close()
                messageApi.open({
                    type: "error",
                    key: messageKey,
                    content: err.message,
                    duration: 3,
                })
            })
    }

    return (
        <div className="flex-row align-center space-between mb-medium  ">
            {contextHolder}
            <div className="flex-row align-center " style={{ width: "65%" }}>
                <h5 className="mr-medium  gray-text">Lane details</h5>
                <div className="flex-row align-center">
                    <Upload
                        {...props}
                        multiple
                        onChange={({ fileList }) => setFileList(fileList)}
                    >
                        <IconButton
                            Icon={UploadOutlined}
                            text="Select File(s)"
                        />
                    </Upload>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleUpload(fileList)
                        }}
                        style={{
                            display: fileList.length === 0 ? "none" : "block",
                        }}
                        disabled={fileList.length === 0}
                        // loading={uploading}
                    >
                        {uploading ? "Uploading" : "Upload"}
                    </Button>

                    {/* <IconButton
                        Icon={PlusOutlined}
                        text="Add new lane"
                        onClick={() => setModalOpen((prevState) => !prevState)}
                    /> */}
                    <IconButton
                        Icon={ExportOutlined}
                        text="Export CSV"
                        onClick={() => {
                            exportOceanData()
                        }}
                    />
                    {exportData && exportData.length > 0 && (
                        <CSVLink
                            data={exportData}
                            className="air-export-csv-comp"
                            filename="E&I Global Ocean Forecast.csv"
                            ref={csvRef}
                        />
                    )}
                    <DatePicker
                        className="ml-medium"
                        placeholder="Due Date"
                        allowClear={false}
                        style={{
                            zoom: "125%",
                            height: 26,
                            width: 120,
                        }}
                        onChange={(date, dateString) => {
                            // console.log(dateString)
                            updateDueDate(dateString)
                        }}
                        // popupClassName="ocean-calendar"
                    />
                    <IconButton
                        Icon={DeleteOutlined}
                        text="Clear Filters"
                        onClick={() => {
                            console.log(filterRef)
                            filterRef.reset(duplicateData, true)
                            removeOceanColumnFilterCookies()
                            dispatch(updateOceanData(duplicateData))
                        }}
                    />
                    {/* <IconButton
                        Icon={SyncOutlined}
                        text={data === null ? "Syncing" : "Sync"}
                        spin={data === null ? true : false}
                        onClick={sync}
                    /> */}
                </div>
            </div>
            <div
                className="flex-row align-center space-between"
                style={{ width: 320 }}
            >
                <h4 className="gray-text">Forecast</h4>
                <IconButton
                    Icon={CheckOutlined}
                    text="Submit"
                    id="ocean-forecast-submit-btn"
                    className="road-forecast-submit-btn"
                    onClick={() => submitNewForecast()}
                />
            </div>
        </div>
    )
}
export default OceanTableHeader
