import axios from "axios"
import {
    UPDATE_DUPLICATE_OCEAN_DATA,
    UPDATE_FILTERED_OCEAN_DATA,
    UPDATE_OCEAN_COLUMNS,
    UPDATE_OCEAN_DATA,
} from "../types/oceanActionTypes"

//Ocean actions
export const updateOceanData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_OCEAN_DATA,
            payload: data,
        })
    } catch (err) {}
}
export const updateDuplicateOceanData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_DUPLICATE_OCEAN_DATA,
            payload: data,
        })
    } catch (err) {}
}

export const updateOceanColumns = (columns) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_OCEAN_COLUMNS,
            payload: columns,
        })
    } catch (err) {}
}
export const updateFilteredOceanData = (data) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_FILTERED_OCEAN_DATA,
            payload: data,
        })
    } catch (err) {}
}

export const getOceanForecastData = (config) => async (dispatch) => {
    try {
        axios
            .get(
                `${process.env.REACT_APP_FAST_API_APP_URL}/ocean/forecast`,
                config
            )
            .then(({ data }) => {
                dispatch(updateOceanData(null))
                return data
            })
            .then((data) => {
                dispatch(updateOceanData(data))
            })
            .catch((err) => console.log(err))
    } catch (err) {}
}
