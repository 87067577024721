import { Card } from "antd"
import Meta from "antd/es/card/Meta"
import React from "react"
import { NavLink } from "react-router-dom"

function HomeCard({ image, text, url }) {
    return (
        <NavLink to={url} className="text-decoration-none">
            <Card
                className="home-card"
                hoverable
                style={{
                    width: 280,
                    marginRight: 15,
                }}
                cover={<img alt="example" src={image} />}
            >
                <div className="flex-row justify-center align-center">
                    <Meta
                        className="flex-row align-center width-fit-content "
                        title={text}
                    />
                </div>
            </Card>
        </NavLink>
    )
}

export default HomeCard
