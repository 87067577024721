export const naRoadColumns = [
    {
        title: "Business Unit",
        dataIndex: "business_unit",
        key: "business_unit",
        filterKey: "business_unit",
        visibility: true,
    },
    {
        title: "Business Segment",
        dataIndex: "business_segment",
        key: "business_segment",
        filterKey: "business_segment",
        visibility: true,
    },
    {
        title: "Ship Point",
        dataIndex: "ship_point",
        key: "ship_point",
        filterKey: "ship_point",
        visibility: true
    },
  
    {
        title: "Origin City",
        dataIndex: "origin_city",
        key: "origin_city",
        filterKey: "origin_city",
        visibility: true,
    },
    {
        title: "Origin State",
        dataIndex: "origin_state",
        key: "origin_state",
        filterKey: "origin_state",
        visibility: true,
    },
    {
        title: "Destination City",
        dataIndex: "destination_city",
        key: "destination_city",
        filterKey: "destination_city",
        visibility: true,
    },
    {
        title: "Destination Point",
        dataIndex: "destination_plant",
        key: "destination_plant",
        filterKey: "destination_plant",
        visibility: true
    },
    {
        title: "Destination State",
        dataIndex: "destination_state",
        key: "destination_state",
        filterKey: "destination_state",
        visibility: true,
    },
    {
        title: "Mileage",
        dataIndex: "mileage",
        key: "mileage",
        filterKey: "mileage",
        visibility: true,
    },
    {
        title: "Hazardous",
        dataIndex: "hazardous",
        key: "hazardous",
        filterKey: "hazardous",
        visibility: true,
    },
    {
        title: "Hazardous Six",
        dataIndex: "hazardous_six",
        key: "hazardous_six",
        filterKey: "hazardous_six",
        visibility: true,
    },
    {
        title: "Temp Control",
        dataIndex: "temp_control",
        key: "temp_control",
        filterKey: "temp_control",
        visibility: true,
    },
    {
        title: "Drop Trailer",
        dataIndex: "drop_trailer",
        key: "drop_trailer",
        filterKey: "drop_trailer",
        visibility: true,
    },
    {
        title: "E Track",
        dataIndex: "e_track",
        key: "e_track",
        filterKey: "e_track",
        visibility: true,
    },
    {
        title: "Dry Van Only",
        dataIndex: "dry_van_only",
        key: "dry_van_only",
        filterKey: "dry_van_only",
        visibility: true,
    },
    {
        title: "Mexico Origin",
        dataIndex: "mexico_origin",
        key: "mexico_origin",
        filterKey: "mexico_origin",
        visibility: true,
    },
    {
        title: "Mexico Destination",
        dataIndex: "mexico_destination",
        key: "mexico_destination",
        filterKey: "mexico_destination",
        visibility: true,
    },
    {
        title: "Tank Endorsement",
        dataIndex: "tank_endorsement",
        key: "tank_endorsement",
        filterKey: "tank_endorsement",
        visibility: true,
    },
    {
        title: "Team Service",
        dataIndex: "team_service",
        key: "team_service",
        filterKey: "team_service",
        visibility: true,
    },

    {
        title: "Month1 History",
        dataIndex: "month1_history",
        key: "month1_history",
        filterKey: "month1_history",
        visibility: true,
    },
    {
        title: "Month2 History",
        dataIndex: "month2_history",
        key: "month2_history",
        filterKey: "month2_history",
        visibility: true,
    },
    {
        title: "Month3 History",
        dataIndex: "month3_history",
        key: "month3_history",
        filterKey: "month3_history",
        visibility: true,
    },
    {
        title: "Month4 History",
        dataIndex: "month4_history",
        key: "month4_history",
        filterKey: "month4_history",
        visibility: true,
    },
    {
        title: "Month5 History",
        dataIndex: "month5_history",
        key: "month5_history",
        filterKey: "month5_history",
        visibility: true,
    },
    {
        title: "Month6 History",
        dataIndex: "month6_history",
        key: "month6_history",
        filterKey: "month6_history",
        visibility: true,
    },
    {
        title: "Six Months History Total",
        dataIndex: "six_months_history",
        key: "six_months_history",
        filterKey: "six_months_history",
        visibility: true,
    },
    {
        title: "Month1 Forecast",
        dataIndex: "month1_forecast",
        key: "month1_forecast",
        filterKey: "month1_forecast",
        visibility: true,
    },
    {
        title: "Month2 Forecast",
        dataIndex: "month2_forecast",
        key: "month2_forecast",
        filterKey: "month2_forecast",
        visibility: true,
    },
    {
        title: "Month3 Forecast",
        dataIndex: "month3_forecast",
        key: "month3_forecast",
        filterKey: "month3_forecast",
        visibility: true,
    },
    {
        title: "Month4 Forecast",
        dataIndex: "month4_forecast",
        key: "month4_forecast",
        filterKey: "month4_forecast",
        visibility: true,
    },
    {
        title: "Month5 Forecast",
        dataIndex: "month5_forecast",
        key: "month5_forecast",
        filterKey: "month5_forecast",
        visibility: true,
    },
    {
        title: "Month6 Forecast",
        dataIndex: "month6_forecast",
        key: "month6_forecast",
        filterKey: "month6_forecast",
        visibility: true,
    },
    {
        title: "Month7 Forecast",
        dataIndex: "month7_forecast",
        key: "month7_forecast",
        filterKey: "month7_forecast",
        visibility: true,
    },
    {
        title: "Month8 Forecast",
        dataIndex: "month8_forecast",
        key: "month8_forecast",
        filterKey: "month8_forecast",
        visibility: true,
    },
    {
        title: "Month9 Forecast",
        dataIndex: "month9_forecast",
        key: "month9_forecast",
        filterKey: "month9_forecast",
        visibility: true,
    },
    {
        title: "Month10 Forecast",
        dataIndex: "month10_forecast",
        key: "month10_forecast",
        filterKey: "month10_forecast",
        visibility: true,
    },
    {
        title: "Month11 Forecast",
        dataIndex: "month11_forecast",
        key: "month11_forecast",
        filterKey: "month11_forecast",
        visibility: true,
    },
    {
        title: "Month12 Forecast",
        dataIndex: "month12_forecast",
        key: "month12_forecast",
        filterKey: "month12_forecast",
        visibility: true,
    },
    {
        title: "Yearly Forecast",
        dataIndex: "year_forecast",
        key: "year_forecast",
        filterKey: "year_forecast",
        visibility: true,
    },
    {
        title: "Last Updated Datetime",
        dataIndex: "last_updated_datetime",
        key: "last_updated_datetime",
        filterKey: "last_updated_datetime",
        visibility: true,
    },
    {
        title: "User Updated",
        dataIndex: "user_updated",
        key: "user_updated",
        filterKey: "user_updated",
        visibility: true,
    },
    {
        title: "Due Date",
        dataIndex: "due_date",
        key: "due_date",
        filterKey: "due_date",
        visibility: true,
    },
    {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        filterKey: "comments",
        visibility: true,
    },
]

export const roadForecastcolumn = [
    {
        key: "forecast column",
        title: "Current forecast",
        dataIndex: "cur_forecast",
        filterKey: "cur_forecast",
        visibility: true,
        Children: <h1>a</h1>,
        style: {
            width: 300,
        },
    },
]
