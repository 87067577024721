import { Select } from "antd"
import React, { useContext } from "react"
import { roadContext } from ".."

function ForecastTableHeader() {
    const {
        setCurrentForecastMonth,
        currentForecastMonthStore,
        setCurrentForecastMonthStore,
        forecastColumns,
    } = useContext(roadContext)
    return (
        <div className="forecast-col-header flex-row">
            <h6>Current Forecast</h6>
            {forecastColumns && (
                <Select
                    defaultValue={currentForecastMonthStore || null}
                    placeholder="forecast month"
                    style={{ width: 120, zoom: "125%" }}
                    onChange={(value) => {
                        // console.log(value)
                        setCurrentForecastMonth(value)
                        setCurrentForecastMonthStore(value)
                    }}
                    options={forecastColumns}
                />
            )}
            {/* <DatePicker
                className="road-forecast-month-picker"
                picker="month"
                style={{
                    height: 35,
                }}
                placeholder="Forecast Month"
                value={currentForecastMonthStore}
                onChange={(date, dateString) => {
                    setCurrentForecastMonth(dateString)
                    setCurrentForecastMonthStore(date)
                }}
            /> */}
        </div>
    )
}

export default ForecastTableHeader
